import {KeyMapped, values} from '@peachy/utility-kit-pure'
import {PlanBenefit} from './PlanBenefit'
import type { Excess } from './Excess'

export class Plan {
    id: string
    name: string
    benefits: KeyMapped<PlanBenefit>
    configId?: string
    excess?: Excess


    findBenefit?(id: string) {
        return values(this.benefits).find(b => b.id === id)
    }

    hasBenefit?(id: string) {
        return values(this.benefits).some(b => b.id === id)
    }

    getBenefitsWithExcess?(): string[] {
        const benefitTypes = values(this.benefits).map(b => b.id)
        return benefitTypes.filter(benefitType => this.excess?.benefitTypes.includes(benefitType))
    }

    hasExcess?() {
        return !!this.excess?.amountInPence
    }

}
