import { type Properties } from '@peachy/utility-kit-pure'
import { SanctionsCheckEvent } from './SanctionsCheckEvent'

export class SanctionsCheckFlagged extends SanctionsCheckEvent {

    constructor(from: Properties<SanctionsCheckFlagged>) {
        super(from)
        this.status = 'FLAGGED'
        this.type = this.constructor.name
    }
}