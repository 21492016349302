import {defineValidator, MessageProducer} from '../validation-core'

import cardValidator from 'card-validator'
import {isNullish} from '@peachy/utility-kit-pure'

export default function IsPaymentCardCode<T>(
    cardNumberProp: string,
    message?: string | MessageProducer<T>
) {
    return defineValidator<T>({
        name: 'IsPaymentCardCode',
        message: 'Please enter your card security code',
        validate({value, instance}) {
            // @ts-ignore
            const cardNumber = instance[cardNumberProp]
            const result = cardValidator.number(cardNumber)
            const maxLength = result.card?.code?.size ?? 3
            return (
                !isNullish(value) && /^\d+$/g.test(value) && value.length === maxLength
            )
        },
    })
}
