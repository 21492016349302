import { API } from '@aws-amplify/api'
import { Auth } from '@aws-amplify/auth'
import { makeApiGatewayClient } from '@peachy/core-domain-client'
import { classList } from '@peachy/utility-kit-pure'

import type { Address } from '@peachy/core-domain-pure'
import type { AddressMatch } from '@punnet/address-lookup-pure'
import { AddressLookupApiDefinition } from '@punnet/address-lookup-pure'
import { Lookup } from '@punnet/web-client-kit-library'


export type AddressLookupProps = {
    onSelect: (address: Address) => void
    onError?: (error: Error) => void
    onBlur?: (value: string) => void
    lookupInputClass?: string
    lookupListClass?: string
    showHideTransitionMarkerClass?: string
    updateTransitionMarkerClass?: string
    enableTransitions?: boolean
    initialSelection?: Address
    initialValue?: string
    embedded?: boolean
}

export function AddressLookup(props: AddressLookupProps) {

    const lookupApi = makeApiGatewayClient(AddressLookupApiDefinition, API, Auth)

    const onSelect = async (addressMatch: AddressMatch) => {
        try {
            const address = await lookupApi.fetchAddress({ addressId: addressMatch.id })
            props.onSelect(address)

        } catch (error) {
            props.onError?.(error as Error)
        }
    }

    const executeQuery = async (query: string) => {
        const addressResponse = await lookupApi.lookupAddress({ searchTerm: query })
        return addressResponse.matches
    }

    return <>
        <Lookup
            executeQuery={executeQuery}
            elementDisplay={(element: AddressMatch) => element.address}
            initialSelection={addressToAddressMatch(props.initialSelection)}
            initialValue={props.initialValue}
            onBlur={props.onBlur}
            onSelect={onSelect}
            onError={props.onError}
            elementEquals={(a: AddressMatch, b: AddressMatch) => a?.id === b?.id}
            placeholder={'Search for an address...'}
            minQueryLength={5}
            maxQueryRateMillis={500}
            lookupInputClass={classList(props.lookupInputClass)}
            lookupListClass={classList(props.lookupListClass)}
            showHideTransitionMarkerClass={props.showHideTransitionMarkerClass}
            updateTransitionMarkerClass={props.updateTransitionMarkerClass}
            enableTransitions={props.enableTransitions}
            embedded={props.embedded}
        />
    </>
}

function addressToAddressMatch(address: Address): AddressMatch {
    if (!address) return null
    return {
        id: '',
        address: `${address.display}`
    }
}

