import { Properties } from '@peachy/utility-kit-pure'
import { AnEvent } from '@punnet/core-event-pure'

import { AccountType } from '../../domain/models/Account'
import { SubscriptionEventData } from './notification-types'


export class SubscriptionActivatedNotification extends AnEvent implements SubscriptionEventData {
    constructor(from: Properties<SubscriptionActivatedNotification>) {
        super()
        Object.assign(this, from)
    }

    accountId: string
    accountType: AccountType
    subscriptionId: string
}
