import { type ParentComponent, Show } from 'solid-js'
import styles from './TableCell.module.css'
import { classList } from '@peachy/utility-kit-pure'

export const TableCell: ParentComponent<{ errorMessage?: string }> = (props) => {
    return (
        <td title={props.errorMessage}>
            {props.children}
            <Show when={props.errorMessage}>
                <i class={classList(styles.icon, 'icon-warning')} />
            </Show>
        </td>
    )
}
