import {createContext, createEffect, onCleanup, onMount, ParentProps, useContext, type Accessor} from 'solid-js'
import {MultiMap} from 'mnemonist'
import {last} from '@peachy/utility-kit-pure'
import type { KeyboardKey } from './keyboardKeys'

const KeyboardContext = createContext<MultiMap<string, KeyboardEventHandler>>()

export type KeyDirection = 'keyup' | 'keydown'

type KeyboardEventHandler = (event: KeyboardEvent) => void

export function KeyboardController(props: ParentProps) {

    const listenerStack = new MultiMap<string, KeyboardEventHandler>()

    const onKeys = (e: KeyboardEvent) => {
        last(listenerStack.get(e.type))?.(e)
    }

    document.addEventListener('keyup', onKeys)
    document.addEventListener('keydown', onKeys)

    onCleanup(() => {
        document.removeEventListener('keyup', onKeys)
        document.removeEventListener('keydown', onKeys)
    })

    return <KeyboardContext.Provider value={listenerStack}>
        {props.children}
    </KeyboardContext.Provider>

}


function popKeyboardListener(type: KeyDirection, listener: KeyboardEventHandler) {
    const listenerStack = useContext(KeyboardContext)
    const listeners = listenerStack.get(type)
    const i = listeners?.indexOf(listener)
    if (i > -1) listeners.length = i
}

function pushKeyboardListener(type: KeyDirection, listener: KeyboardEventHandler) {
    const listenerStack = useContext(KeyboardContext)
    listenerStack.set(type, listener)
}


function onKey(
    key: KeyboardKey, 
    keyDirection: KeyDirection,
    handler: KeyboardEventHandler
) {
    const listener = (e: KeyboardEvent) => {
        if (e.key === key && handler) handler(e)
    }

    onMount(() => {
        pushKeyboardListener(keyDirection, listener)
    })
    onCleanup(() => {
        popKeyboardListener(keyDirection, listener)
    })
}


export function onKeyDown(
    key: KeyboardKey, 
    handler: KeyboardEventHandler
) {
    onKey(key, 'keydown', handler)
}

export function onKeyUp(
    key: KeyboardKey, 
    handler: KeyboardEventHandler
) {
    onKey(key, 'keyup', handler)
}



function onKeyWhile(
    key: KeyboardKey, 
    keyDirection: KeyDirection, 
    condition: Accessor<boolean>, 
    handler: KeyboardEventHandler
) {
    const listener = (e: KeyboardEvent) => {
        if (e.key === key && condition() && handler) handler(e)
    }
    createEffect(() => {
       if (condition()) {
            pushKeyboardListener(keyDirection, listener)
       } else {
            popKeyboardListener(keyDirection, listener)
       }
    })
}


export function onKeyUpWhile(
    key: KeyboardKey, 
    condition: Accessor<boolean>, 
    handler: KeyboardEventHandler
) {
    onKeyWhile(key, 'keyup', condition, handler)
}


export function onKeyDownWhile(
    key: KeyboardKey, 
    condition: Accessor<boolean>, 
    handler: KeyboardEventHandler
) {
    onKeyWhile(key, 'keydown', condition, handler)
}



export function onEscapeKeyWhile(
    condition: Accessor<boolean>, 
    handler: KeyboardEventHandler
) {
    onKeyDownWhile('Escape', condition, handler)
}

export function onEnterKeyWhile(
    condition: Accessor<boolean>, 
    handler: KeyboardEventHandler
) {
    onKeyDownWhile('Enter', condition, handler)
}

