
import { type Component } from 'solid-js'
import { Button } from '../../../../components/Button/Buttons'
import { useNavigate } from '@solidjs/router'
import styles from './Welcome.module.css'

export const Welcome: Component = () => {

    // TODO: user should only see this page if they have just purchased a quote - otherwise redirect to home

    const navigate = useNavigate()

    return (
        <div class={styles.Welcome}>
            <p>It's official!</p>
            <h1 class={styles.Header}>WELCOME TO SOVEREIGN</h1>
            <div class={styles.Text}>
                <p>
                    What now? We've emailed you your policy documents and the information you need to log in to manage your company scheme, 
                    including adding additional users. We've also emailed your finance contact to add the direct debit details
                </p>
                <p>
                    We've emailed all employees on the scheme with an invite to download our app and log into their Sovereign account
                </p>
            </div>
            <div class={styles.Navigation}>
                <Button class={styles.Button} onClick={() => navigate('/')}>Go to portal</Button>
            </div>
        </div>
    )
}