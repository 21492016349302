import { useNavigate } from '@solidjs/router'
import { type ParentComponent } from 'solid-js'
import { getLatestQuote } from '../services/AccountProvider'
import { isRouteAccessible } from '../AppRoutes'

// Home page component - handles route navigation so the user sees the correct page after login
export const Home: ParentComponent = (props) => {
    const navigate = useNavigate()

    if (getLatestQuote()) {
        const quoteId = getLatestQuote()?.subscription.id
        navigate(`/quotes/${quoteId}`, { replace: true })
        return
    }

    // quotes route is accessible for brokers - forward here if it's accessible (this is guaranteed by AppRoutes)
    if (isRouteAccessible('/quotes')) {
        navigate('/quotes', { replace: true })
        return
    }

    // overview route is accessible for customers - forward here if it's accessible (this is guaranteed by AppRoutes)
    // TODO: need to add check for active account (this will be added in AppRoutes, not here)
    if (isRouteAccessible('/overview')) {
        navigate('/overview', { replace: true })
        return
    }

    return <>{props.children}</>
}
