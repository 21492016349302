import { Properties } from '@peachy/utility-kit-pure'
import { AnEvent } from '@punnet/core-event-pure'

import type { AccountType } from '../../../domain/models/Account'
import type { CancellationType } from '../../../domain/models/CancellationType'
import type { CancellationEventData, SubscriptionEventData } from '../../notifications/notification-types'


export class CompanyCancellationEmailSendRequest extends AnEvent implements SubscriptionEventData, CancellationEventData {
    constructor(from: Properties<CompanyCancellationEmailSendRequest>) {
        super()
        Object.assign(this, from)
    }

    accountId: string
    accountType: AccountType
    cancellationReason: string
    cancellationType: CancellationType
    subscriptionId: string
}
