import { createMemo, createSignal, type Accessor } from 'solid-js'
import { useManageExpectedCsvFields, useManageStoreActions } from '../../../../app/store/quotes/manageEmployeesStore'
import type { ProgressMessage } from '../../../../app/store/quotes/mapCsvToPolicies'
import { FileReaderButton, GreenOutlineButton, GreyButton, SecondaryButton } from '../../../Button/Buttons'
import styles from './BulkAddMembers.module.css'

type BulkAddMembersProps = {
    onComplete?: () => void
    canMap: Accessor<boolean>
    onManualUpload: () => void
    onUpdate: () => void
    footerStyles?: string
}

const completeMessage: ProgressMessage = {
    style: 'PROGRESS',
    text: 'Upload complete!'
}

export function BulkAddMembers(props: BulkAddMembersProps) {
    const expectedCsvFields = useManageExpectedCsvFields()
    const { parseAddPolicies } = useManageStoreActions()

    const [message, setMessage] = createSignal({} as ProgressMessage)
    const templateLink = createMemo(() => expectedCsvFields.join(','))

    const pasteFromClipboard = () => navigator.clipboard.readText().then((text) => {
        parseAddPolicies(text, props.canMap, setMessage).then(() => setMessage(completeMessage))
    })
        .catch(e => {
            console.error(e)
            setMessage({
                style: 'ERROR',
                text: 'There was a problem with the data. Please check the header names are correct and that only the header and relevant employees were selected'
            })
        })

    const onFileRead = (data: string) => parseAddPolicies(data, props.canMap, setMessage).then(() => setMessage(completeMessage))
        .catch(e => {
            console.error(e)
            setMessage({
                style: 'ERROR',
                text: 'There was a problem with the data. Please check the header names are correct and that all other columns are empty'
            })
        })

    // TODO-CC Style hyperlinks
    return (
        <section>
            <h3>Bulk upload</h3>

            <p>If you have a list of employees in a spreadsheet, you can add them all easily.
                The spreadsheet must have the correct column headings - just paste them into our <a download="employee.csv"
                    href={`data:text/csv;charset=utf-8,${templateLink()}`}>template</a> and then upload.</p>
            <p>To paste directly into the fields on the previous page, copy the title row and all the members you want to add, then click Paste</p>
            <p>To upload a file, it must be saved as a csv</p>
            <p>Please note you can only add employees. Additional members can be added by employees when they log into the app</p>

            <ProgressMessageText message={message} />

            <div class={styles.buttonsContainer}>
                <FileReaderButton onFileRead={onFileRead} accept=".csv">
                    Upload csv file
                </FileReaderButton>
                <GreenOutlineButton onClick={pasteFromClipboard}>
                    Paste
                </GreenOutlineButton>
            </div>

            <p>Download <a download="employee.csv"
                href={`data:text/csv;charset=utf-8,${templateLink()}`}>employee csv</a></p>

            <div class={props.footerStyles}>
                <GreyButton onClick={props.onManualUpload}>Manual upload</GreyButton>
                <SecondaryButton onClick={props.onUpdate}>Update quote</SecondaryButton>
            </div>
        </section >
    )
}

function ProgressMessageText(props: { message: Accessor<ProgressMessage> }) {
    return (
        <div>
            <p>{props.message().text}</p>
        </div>
    )
}
