import { useIamService } from '@punnet/iam-client'
import { Navigate, type RouteDefinition } from '@solidjs/router'
import { createSignal, type JSXElement } from 'solid-js'
import { Home } from './pages/Home'
import { Overview } from './pages/Overview/Overview'
import { ContactDetails } from './pages/Quotes/NewQuote/ContactDetails'
import { NewQuote } from './pages/Quotes/NewQuote/NewQuote'
import { PlanDetails } from './pages/Quotes/NewQuote/PlanDetails'
import { PurchaseQuoteRoutes } from './pages/Quotes/PurchaseQuote/Routes'
import { Welcome } from './pages/Quotes/PurchaseQuote/Welcome'
import { Quotes } from './pages/Quotes/Quotes'
import { getLatestQuote } from './services/AccountProvider'
import { getPermissions } from './services/permissions/PermissionsController'
import { PreviousButton } from './pages/Quotes/PurchaseQuote/NavigationButtons'
import { Members } from './pages/Members/Members'

// extend the solid js router to add additional functionality for determining if 
// i) a route is accessible
// ii) a route has it's own navigation / actions
type PermissionedRouteDefinition = RouteDefinition & {
    canAccess: () => boolean
    navigation?: {
        primary?: JSXElement
        secondary?: JSXElement
    }
    regex?: string
}

const isSignedIn = () => useIamService().isSignedIn()
const canShareQuote = () => getPermissions().canShareQuote()
const canPurchaseQuote = () => getPermissions().canPurchaseQuote()

//TODO: put these elsewhere and implement! :)
const SaveNewQuote = () => <span>Save & close</span>

const allRoutes = [
    {
        path: '/',
        component: Home,
        canAccess: () => true
    },
    {
        path: '/quotes',
        component: Quotes,
        canAccess: () => isSignedIn() && canShareQuote()
    },
    {
        path: '/quotes/new',
        component: NewQuote,
        canAccess: () => isSignedIn() && canShareQuote(),
        navigation: {
            secondary: <SaveNewQuote/>
        },
        children: [
            {
                path: '/',
                component: PlanDetails
            },
            {
                path: '/contact',
                component: ContactDetails,
                canAccess: () => isSignedIn() && canShareQuote(),
                navigation: {
                    secondary: <SaveNewQuote/>
                }
            }
        ]
    },
    {
        path: '/quotes/:quoteId/*',
        component: PurchaseQuoteRoutes,
        canAccess: () => isSignedIn() && canPurchaseQuote() && getLatestQuote(),
        navigation: {
            primary: <PreviousButton/>
        },
        regex: '^/quotes/[\\w-]+(\\/.*)?'
    },
    {
        path: '/quotes/purchased',
        component: Welcome,
        canAccess: () => isSignedIn() && canPurchaseQuote() && getLatestQuote()
    },
    {
        path: '/overview',
        component: Overview,
        canAccess: () => isSignedIn() && canPurchaseQuote() && !getLatestQuote()
    },
    {
        path: '/members',
        component: Members,
        canAccess: () => isSignedIn() && canPurchaseQuote() && !getLatestQuote()
    },
    {
        path: '*',
        component: () => <Navigate href="/"/>,
        canAccess: () => true
    }
] as PermissionedRouteDefinition[]


const matchPath = (path: string, route: PermissionedRouteDefinition) => {
    if (route.regex) {
        const regex = new RegExp(route.regex)
        return regex.test(path)
    }
    return route.path === path
}

// flatten the routes so we can match against the full path of both root routes and child routes
const flattenedRoutes = allRoutes.flatMap(route => {
    const childrenWithFullPath = (route.children ?? []).map((child: PermissionedRouteDefinition) => ({
        ...child,
        path: `${route.path}${child.path}`
    }))
    return [route, ...childrenWithFullPath]
})

// used to store the accessible routes - this is used for the router configuration
const [accessibleRoutes, setAccessibleRoutes] = createSignal<PermissionedRouteDefinition[]>([])


// used by the router configuration - this gets called when one of the signals changes
// we therefore can store the routes the user has access to globally and use it to determine if a route is accessible
export function getConfigurableRoutes() {
    const filteredRoutes = () => allRoutes.filter((route: PermissionedRouteDefinition) => route.canAccess())
    setAccessibleRoutes(filteredRoutes())
    console.log(filteredRoutes().map(route => route.path))
    return filteredRoutes()
}

export function isRouteAccessible(path: string) {
    return accessibleRoutes().some(route => matchPath(path, route))
}

export function getRouteNavigationPrimary(path: string): JSXElement | undefined {
    const route = flattenedRoutes.find(route => matchPath(path, route))
    return route?.navigation?.primary
}

export function getRouteNavigationSecondary(path: string): JSXElement | undefined {
    const route = flattenedRoutes.find(route => matchPath(path, route))
    return route?.navigation?.secondary
}
