import { newUUID, type Draft } from '@peachy/utility-kit-pure'
import { type Benefit } from '@punnet/product-client'
import { Company, Life, Plan, Policy } from '@punnet/subscription-pure'
import { createWithStore } from 'solid-zustand'
import { usePlanConfig } from '../../hooks/usePlanConfig'
import { createPlans } from '../createPlans'
import { addBenefit } from './addBenefit'
import { createPolicy } from './createPolicy'
import { removeBenefit } from './removeBenefit'
import { removeLife } from './removeLife'

const planConfig = usePlanConfig(), plansFromConfig = createPlans(planConfig)

export const MIN_POLICIES = 1

export type QuoteAndBuyStateData = {
    plans: Plan[],
    policies: Draft<Policy>[],
    company: Company,
    startDate: number
    quoteRequestId: string

    //TODO: this state should go on a separate store
    customerEmailAddress?: string
}

export type QuoteAndBuyState = QuoteAndBuyStateData & {
    actions: {
        hydrate: (data: QuoteAndBuyStateData) => void
        setCompany: (company: Company) => void
        setStartDate: (startDate: number) => void
        addPolicy: (policy: Draft<Policy>) => void
        updatePolicies: (policies: Draft<Policy>[]) => void
        removePolicy: (policy: Draft<Policy>) => void
        addLife: (life: Draft<Life>) => void
        removeLife: (life: Draft<Life>) => void
        addBenefit: (benefit: Benefit, planId?: string) => void
        removeBenefit: (benefit: Benefit, planId?: string) => void
        requestQuote: () => void
        canQuote: () => boolean
        clear: () => void
        getState: () => QuoteAndBuyStateData

        //TODO: this should go on a separate store
        setCustomerEmailAddress: (emailAddress: string) => void
    }
}

// DO NOT EXPORT THIS STORE DIRECTLY

const initialState = (): QuoteAndBuyStateData => ({
    plans: plansFromConfig,
    policies: [] as Draft<Policy>[],
    company: undefined as Company,
    startDate: Date.now(),
    quoteRequestId: '',
    customerEmailAddress: ''
})

const useStore = createWithStore<QuoteAndBuyState>((set, get) => ({
    ...initialState(),
    actions: {
        // allow for hydration of the store (TODO: should probably hydrate via the Storage middleware)
        hydrate: (data: QuoteAndBuyStateData) => {
            set({ ...data })
            get().actions.requestQuote()
        },
        setCompany: (company: Company) => {
            set({ company })
        },
        setStartDate: (startDate: number) => {
            set({ startDate })
        },
        addPolicy: (policy: Draft<Policy>) => {
            set(state => ({ policies: [...state.policies, policy] }))
            get().actions.requestQuote()
        },
        updatePolicies: (policies: Draft<Policy>[]) => {
            set({ policies })
            get().actions.requestQuote()
        },
        removePolicy: (policy: Draft<Policy>) => {
            set(state => ({ policies: state.policies.filter(p => p.id !== policy.id) }))
            get().actions.requestQuote()
        },
        addLife: (life: Draft<Life>) => {
            set(state => ({ policies: [...state.policies, createPolicy(life)] }))
            get().actions.requestQuote()
        },
        removeLife: (life: Draft<Life>) => {
            set(state => ({ policies: removeLife(state.policies, life) }))
            get().actions.requestQuote()
        },
        addBenefit: (benefit: Benefit, planId?: string) => {
            const plans = get().plans
            addBenefit(plans, benefit, planId)
            set({ plans })
            get().actions.requestQuote()
        },
        removeBenefit: (benefit: Benefit, planId?: string) => {
            const plans = get().plans
            removeBenefit(plans, benefit, planId)
            set({ plans })
            get().actions.requestQuote()
        },
        requestQuote: () => set({ quoteRequestId: newUUID() }),
        canQuote: () => canQuote(),
        clear: () => set({ ...initialState() }),
        getState: () => get() as QuoteAndBuyStateData,

        //TODO: this should go on a separate store
        setCustomerEmailAddress: (emailAddress: string) => set({ customerEmailAddress: emailAddress })
    }
}))


// export individually (better performance) - see https://tkdodo.eu/blog/working-with-zustand#prefer-atomic-selectors
export const usePlans = () => useStore(state => state.plans)
export const usePolicies = () => useStore(state => state.policies)
export const useLives = () => useStore(state => state.policies.flatMap(p => Object.values(p.lives)))
export const useCompany = () => useStore().company
export const useStartDate = () => useStore().startDate
export const useCustomerEmailAddress = () => useStore().customerEmailAddress

// one export for all actions - see https://tkdodo.eu/blog/working-with-zustand#separate-actions-from-state
export const useStoreActions = useStore(state => state.actions)

export const canQuote = (): boolean => (usePolicies().length >= MIN_POLICIES)

export const subscribeToStore = useStore.subscribe