import { IdCheckEvent } from './IdCheckEvent'
import { Properties } from '@peachy/utility-kit-pure'

export class IdCheckFailed extends IdCheckEvent {

    constructor(from: Properties<IdCheckFailed>) {
        super(from)
        this.status = 'FAILED'
        this.type = this.constructor.name
    }
}


