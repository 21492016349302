import { PageRouter } from '../../../../components/PageRouter/PageRouter'
import type { PageRouterPath } from '../../../../components/PageRouter/PageRouterPath'
import { PlanDetails } from '../PurchaseQuote/PlanDetails'
import { Authorise } from './Authorise'
import { DirectDebitAuthority } from './DirectDebitAuthority'
import { DirectDebitDetails } from './DirectDebitDetails'
import { PurchaseQuote } from './PurchaseQuote'
import { PurchaseQuoteProvider } from './PurchaseQuoteProvider'
import { Schedules } from './Schedules'
import { Upgrades } from './Upgrades'


const BASE_PATH = '/quotes/:quoteId'
const PAGE_2_PATH = `${BASE_PATH}/upgrades`
const PAGE_3_PATH = `${BASE_PATH}/schedules`

const routes = [
    {
        component: PlanDetails,
        path: BASE_PATH,
        canAccess: () => true
    },
    {
        component: Upgrades,
        path: PAGE_2_PATH,
        canAccess: () => false
    },
    {
        component: Schedules,
        path: PAGE_3_PATH,
        canAccess: () => false
    },
    {
        component: DirectDebitAuthority,
        path: `${BASE_PATH}/dd-authority`,
        canAccess: () => false
    },
    {
        component: DirectDebitDetails,
        path: `${BASE_PATH}/dd-details`,
        canAccess: () => false
    },
    {
        component: Authorise,
        path: `${BASE_PATH}/authorise`,
        canAccess: () => false
    }
] as PageRouterPath[]


export function PurchaseQuoteRoutes() {
    return (
        <PurchaseQuoteProvider>
            <PurchaseQuote>
                <PageRouter routes={routes}></PageRouter>
            </PurchaseQuote>
        </PurchaseQuoteProvider>
    )
}