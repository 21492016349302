import { type Draft } from '@peachy/utility-kit-pure'
import { Life, Plan, Policy } from '@punnet/subscription-pure'
import type { Accessor, Setter } from 'solid-js'
import { createWithStore } from 'solid-zustand'
import { createDraftPolicy } from './createPolicy'
import { mapCsvToPolicies, type ProgressMessage } from './mapCsvToPolicies'
import { updateLife } from './updateLife'


interface ManageEmployeesStoreInitProps {
    plans: Plan[]
    policies: Draft<Policy>[]
    startDate: number
    minPolicyCount: number
    lifeValidation: LifeValidation
}

interface ManageEmployeesStoreProps extends ManageEmployeesStoreInitProps {
    expectedCsvFields: string[]
}

export interface ManageEmployeesStoreActions {
    updateLife: (policyId: string, life: Draft<Life>) => void
    addPolicy: () => void
    removePolicy: (policy: Draft<Policy>) => void
    canRemovePolicy: () => boolean
    parseAddPolicies: (text: string, canMap: Accessor<boolean>, setMessage?: Setter<ProgressMessage>) => Promise<void>
    init: (state: ManageEmployeesStoreInitProps) => void
}

export type LifeValidation = {
    firstName: (life: Draft<Life>) => boolean,
    lastName: (life: Draft<Life>) => boolean,
}

type ManageEmployeesStoreState = ManageEmployeesStoreProps & {
    actions: ManageEmployeesStoreActions
}

const EXPECTED_FIELDS = [
    'firstname',
    'lastname',
    'dateOfBirth',
    'address',
    'email',
    'planType'
]

const useManageStore = createWithStore<ManageEmployeesStoreState>((set, state) => ({
    plans: [],
    policies: [],
    startDate: Date.now(),
    minPolicyCount: 0,
    expectedCsvFields: EXPECTED_FIELDS,
    lifeValidation: {
        firstName: () => false,
        lastName: () => false,
    },
    actions: {
        updateLife: (policyId: string, life: Draft<Life>) => set(state => ({ policies: updateLife(state.policies, policyId, life) })),
        addPolicy: () => set(state => ({
            policies: [...state.policies, createDraftPolicy(state.startDate, state.plans[0]?.id ?? undefined)]
        })),
        removePolicy: (policy: Draft<Policy>) => set(state => ({ policies: state.policies.filter(p => p.id !== policy.id) })),
        canRemovePolicy: () => canRemovePolicy(),
        //TODO Write a test and break this down into more function. Getting complex
        parseAddPolicies: async (text, canMap, setMessage) => {
            const bulkPolicies: Draft<Policy>[] = await mapCsvToPolicies({
                plans: state().plans,
                startDate: state().startDate,
                expectedCsvFields: state().expectedCsvFields,
                text,
                canMap: canMap,
                setMessage
            })

            const nonEmptyPolicies = state().policies.filter(p => {
                const life = Object.values(p.lives).find(l => l.type === 'PRIMARY')
                return life.firstname || life.lastname || life.dateOfBirth || life.address || life.email
            })

            const totalPoliciesCount = bulkPolicies.length + nonEmptyPolicies.length
            const emptyPolicies: Draft<Policy>[] = []

            // TODO won't work because min policy is 1
            if (totalPoliciesCount < state().minPolicyCount) {
                for (let i = 0; i < state().minPolicyCount - totalPoliciesCount; i++) {
                    emptyPolicies.push(createDraftPolicy(state().startDate, state().plans[0]?.id ?? undefined))
                }
            }

            return set(state => ({ policies: [...nonEmptyPolicies, ...bulkPolicies, ...emptyPolicies] }))
        },
        init: (state: ManageEmployeesStoreInitProps) => set({ ...state })
    }
}))

/* external state - exposed to the UI */
// export individually (better performance) - see https://tkdodo.eu/blog/working-with-zustand#prefer-atomic-selectors
export const useManagePlans = () => useManageStore(state => state.plans)
export const useManagePolicies = () => useManageStore(state => state.policies)
export const useManageMinPolicyCount = () => useManageStore().minPolicyCount
export const useManageExpectedCsvFields = () => useManageStore(state => state.expectedCsvFields)
export const useManageLifeValidation = () => useManageStore(state => state.lifeValidation)
export const useManageStoreActions = () => useManageStore<ManageEmployeesStoreActions>(state => state.actions)

const canRemovePolicy = (): boolean => useManagePolicies().length > useManageMinPolicyCount()