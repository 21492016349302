import { API } from '@aws-amplify/api'
import { Auth } from '@aws-amplify/auth'
import { makeApiGatewayClient } from '@peachy/core-domain-client'
import type { Company } from '@peachy/core-domain-pure'
import { classList } from '@peachy/utility-kit-pure'
import { CompanyLookupApiDefinition, type CompanyMatch } from '@punnet/company-lookup-pure'
import { Lookup } from '@punnet/web-client-kit-library'


export type CompanyLookupProps = {
    onSelect: (company: Company) => void
    onError?: (error: Error) => void
    lookupInputClass?: string
    lookupListClass?: string
    showHideTransitionMarkerClass?: string
    updateTransitionMarkerClass?: string
    enableTransitions?: boolean
    initialSelection?: Company
    embedded?: boolean
}

export function CompanyLookup(props: CompanyLookupProps) {

    const lookupApi = makeApiGatewayClient(CompanyLookupApiDefinition, API, Auth)

    const onSelect = async(companyMatch: CompanyMatch) => {
        try {
            const company = await lookupApi.fetchCompany({companyId: companyMatch.id})
            props.onSelect(company)
            
        } catch (error) {
            props.onError?.(error as Error)
        }
    }

    const executeQuery = async (query: string) => {
        const companyResponse = await lookupApi.lookupCompany({searchTerm: query})
        return companyResponse.matches
    }

    return <>
        <Lookup
            executeQuery={executeQuery}
            elementDisplay={(element: CompanyMatch) => element.address}
            initialSelection={companyToCompanyMatch(props.initialSelection)}
            onSelect={onSelect}
            onError={props.onError}
            elementEquals={(a: CompanyMatch, b: CompanyMatch) => a?.id === b?.id}
            placeholder={'Search for a company...'}
            minQueryLength={3}
            maxQueryRateMillis={500}
            lookupInputClass={classList(props.lookupInputClass)}
            lookupListClass={classList(props.lookupListClass)}
            showHideTransitionMarkerClass={props.showHideTransitionMarkerClass}
            updateTransitionMarkerClass={props.updateTransitionMarkerClass}
            enableTransitions={props.enableTransitions}
            embedded={props.embedded}
        />
    </>
}


function companyToCompanyMatch(company: Company): CompanyMatch {
    if (!company) return null
    return {
        id: company.id,
        address: `${company.name}, ${company.address.display}`
    }
}

