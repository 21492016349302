import {ParentProps, Show} from 'solid-js'
import {classList, NOOP} from '@peachy/utility-kit-pure'
import styles from './TextBox.module.css'

type Propagate = boolean | void
type OnKeyHandler = (e: KeyboardEvent) => Propagate

// TODO-CC we extend the HTML Input attributes - unnecessary overrides of properties
// https://bitbucket.org/peachy-punnet/peachy-mono-repo/pull-requests/453/overview#comment-557246266

export type TextBoxProps = ParentProps<{
    value: string
    placeholder?: string
    onInput?: (value: string) => void
    realOnChange?: (value: string) => void
    onKeyDown?: OnKeyHandler
    onKeyUp?: OnKeyHandler
    autoFocus?: boolean
    onBlur?: (e: Event) => void
    onFocus?: (e: Event) => void
    type?: string
    class?: string
    inputClass?: string
    disabled?: boolean
    ref?: any
    inputRef?: any
    readonly?: boolean
    min?: number
    max?: number
    selectAllOnFocus?: boolean
    spin?: boolean
}>

export function TextBox(props: TextBoxProps) {

    const onInput = (event: Event) => {
        props.onInput?.(valueFrom(event))
    }

    const onFocus = (event: any) => {
        if (props.selectAllOnFocus) {
            event.currentTarget.select()
        }

        props.onFocus?.(event)
    }

    const inputCss = () => classList(props.readonly ? styles.readonly : '', props?.inputClass)

    return (
        <label ref={props.ref} class={props.class ?? ''}>
            <input autofocus={props.autoFocus} spellcheck={false} type={props.type ?? 'text'} value={props.value} placeholder={props.placeholder}
                onInput={onInput}
                onChange={e => props.realOnChange?.(valueFrom(e))}
                onKeyDown={props.onKeyDown ?? NOOP}
                onKeyUp={props.onKeyUp}
                onFocus={onFocus}
                onBlur={props.onBlur ?? NOOP}
                disabled={props.disabled}
                readonly={props.readonly}
                ref={props.inputRef}
                min={props.min}
                max={props.max}
                class={inputCss()}
            />
            <Show when={props.spin}>
                <i class="fa-duotone fa-spinner fa-spin-pulse" style={{
                    '--fa-primary-color': 'var(--peachy-pink)',
                    '--fa-secondary-color': 'var(--peachy-pink-pale)',
                    position: 'absolute',
                    top: '10px',
                    right: '10px'
                }}></i>
            </Show>
            {props.children}
        </label>
    )
}

export function valueFrom(event: Event) {
    const input = event.currentTarget as HTMLInputElement
    return input.value
}
