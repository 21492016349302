import { Policy, Life } from '@punnet/subscription-pure'
import type { Draft } from '@peachy/utility-kit-pure'
import { produce } from 'immer'

export function removeLife (policies: Draft<Policy>[], life: Draft<Life>) {

    return produce(policies, draftState => {
        const matchingPolicy = draftState.find(p => p.lives[life.id])

        if (life.type === 'PRIMARY') {
            return draftState.filter(p => p.id !== matchingPolicy.id)
        }

        if (matchingPolicy) {
            delete draftState.find(p => p.id === matchingPolicy.id).lives[life.id]
        }

        return draftState
    })
}