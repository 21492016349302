import { type Properties } from '@peachy/utility-kit-pure'
import { type SanctionsCheckStatus } from '../../domain/models/statuses'
import { AnEvent } from '@punnet/core-event-pure'

export class SanctionsCheckEvent extends AnEvent {
    constructor(from: Properties<SanctionsCheckEvent>) {
        super()
        Object.assign(this, from)
    }

    complyAdvEventType: string
    complyAdvMatchStatus: string

    email: string
    cognitoId: string
    status: SanctionsCheckStatus
    s3Url: string
    complyAdvJobId: string
    complyAdvPortalUrl: string
}
