import { useSubscriptionService } from '@punnet/subscription-web-client'
import { createToggleSignal } from '@punnet/web-client-kit-library'
import { useNavigate } from '@solidjs/router'
import { Show, createEffect, createSignal } from 'solid-js'
import { PrimaryButton, SecondaryButton } from '../../../../components/Button/Buttons'
import { Pulse } from '../../../../components/Pulse/Pulse'
import { persistQuote } from '../../../services/persistQuote'
import { mapToSubscriptionRequest } from '../../../store/quotes/mapToSubscriptionRequest'
import { useCustomerEmailAddress, useStoreActions } from '../../../store/quotes/quoteAndBuyStore'
import { Label, Title } from '../Quote'
import { SuccessModal } from './SuccessModal'
import styles from './ContactDetails.module.css'


export const ContactDetails = () => {

    const navigate = useNavigate()
    const subscriptionService = useSubscriptionService()

    const [isSuccessModalOpen, toggleSuccessModalOpen] = createToggleSignal(false)

    const onBack = () => navigate('/quotes/new')

    //validate that we can access this route
    createEffect(() => {
        const hasAccess = useStoreActions.canQuote()
        if (!hasAccess) {
            console.error('no access to this route!')
            onBack()
        }
    })

    //TODO: a lot of this stuff is duplicated in Authorise.tsx - refactor!
    const [isSubmitting, setIsSubmitting] = createSignal(false)
    const [error, setError] = createSignal('')

    const inviteUser = async (quoteId: string) => {
        console.log(`inviting user`)

        return subscriptionService.inviteCompanyAdmin({
            email: email(),
            firstName: '',
            lastName: '',
            quoteId
        })
    }

    const onShare = async (e: Event) => {
        setIsSubmitting(true)
        setError('')

        try {
            const quoteRequest = mapToSubscriptionRequest(useStoreActions.getState())  
            await persistQuote(subscriptionService, quoteRequest)
            await inviteUser(quoteRequest.subscription.id)
            toggleSuccessModalOpen()
        } catch (error) {
            console.error('error sharing quote', error)
            setError('An unknown error occurred')
        } finally {
            setIsSubmitting(false)
        }
    }

    const setCustomerEmailAddress = useStoreActions.setCustomerEmailAddress

    const [email, setEmail] = createSignal(useCustomerEmailAddress() ?? '')

    const handleEmailInput = (e: any) => setEmail(e.target.checkValidity() ? e.target.value : '')
    const handleEmailChange = (e: any) => setCustomerEmailAddress(email())
    const isValid = () => email().length > 0

    return (
        <>
            <Title>Contact details</Title>

            <div class={styles.ContactDetails}>

                <div class={styles.MainContact}>
                    <Label>Main contact</Label>
                    {/* TODO: crude implementation of showing errors for now - refactor! */}
                    <Show when={error()}>
                        <span style={{color: 'red'}}>{error()}</span>
                    </Show>
                    <input type="email" name="email" placeholder="Email address" value={useCustomerEmailAddress()} required onInput={handleEmailInput} onChange={handleEmailChange} autocomplete="off"/>
                    <p class={styles.Info}>This user has to accept the terms & conditions and authorise purchase of the company scheme. They can add finance contact details if necessary.</p>
                </div>

                <div class={styles.Buttons}>
                    <SecondaryButton onClick={onBack}>Back</SecondaryButton>
                    <PrimaryButton onClick={onShare} disabled={!isValid()}>Share</PrimaryButton>
                </div>

            </div>

            <Pulse isShown={isSubmitting()}/>

            <Show when={isSuccessModalOpen()}>
                <SuccessModal onClose={toggleSuccessModalOpen} />
            </Show>
        </>
    )
}
