import type { SubscriptionQuoteRequest, SubscriptionQuoteResponse } from '@punnet/subscription-pure'
import { useSubscriptionService } from '@punnet/subscription-web-client'
import { debounce } from '@solid-primitives/scheduled'
import { createContext, createSignal, onCleanup, useContext, type Accessor, type ParentComponent } from 'solid-js'
import { subscribeToStore, type QuoteAndBuyState } from '../../../store/quotes/quoteAndBuyStore'
import { mapToSubscriptionRequest } from '../../../store/quotes/mapToSubscriptionRequest'

const MAX_QUOTE_RATE = 500

type QuoteControllerContextState = {
    quoteResponse: Accessor<SubscriptionQuoteResponse>
}

const QuoteControllerContext = createContext<QuoteControllerContextState>()


type QuoteControllerProps = {
    savedQuote?: SubscriptionQuoteRequest | undefined
}

export const QuoteController: ParentComponent<QuoteControllerProps> = (props) => {
    const subscriptionService = useSubscriptionService()
    const [quoteResponse, setQuoteResponse] = createSignal<SubscriptionQuoteResponse>()

    //this has to go here and not in the subscribeToStore/requestQuote as it barfs if it's in there :)
    const savedQuote = props.savedQuote

    const requestQuote = debounce(async (state: QuoteAndBuyState) => {
        if (state?.actions.canQuote()) {
            console.log('quoting - create quote request object')
            const quoteRequest = mapToSubscriptionRequest(state, savedQuote) as SubscriptionQuoteRequest
            console.log('requesting quote', quoteRequest)
            const response = await subscriptionService.getSmeQuote(quoteRequest)
            console.log('received quote', response.quoteRequestId, response.totalMonthlyPremium)
            setQuoteResponse(response)
        }
    }, MAX_QUOTE_RATE)

    //TODO: should really subscribe to the store via selector (see https://github.com/pmndrs/zustand?tab=readme-ov-file#using-subscribe-with-selector)
    const unsubscribe = subscribeToStore((state, prevState) => {
        if (state.quoteRequestId !== prevState.quoteRequestId) {
            setQuoteResponse(undefined)
            requestQuote(state)
        }
    })

    onCleanup(() => unsubscribe())

    return (
        <QuoteControllerContext.Provider value={{ quoteResponse }}>
            {props.children}
        </QuoteControllerContext.Provider>
    )
}

export function useQuoteResponse() {
    return useContext(QuoteControllerContext).quoteResponse
}
