import { IdCheckEvent } from './IdCheckEvent'
import { Properties } from '@peachy/utility-kit-pure'

export class IdCheckPassed extends IdCheckEvent {

    constructor(from: Properties<IdCheckPassed>) {
        super(from)
        this.type = this.constructor.name
    }
}
