import {IdCheckEvent} from './IdCheckEvent'
import {Properties} from '@peachy/utility-kit-pure'

export class IdCheckFlagged extends IdCheckEvent {

    constructor(from: Properties<IdCheckFlagged>) {
        super(from)
        this.status = 'FLAGGED'
        this.type = this.constructor.name
    }
}


