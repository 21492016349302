import { Spinner } from '@punnet/web-client-kit-library'
import { useNavigate } from '@solidjs/router'
import { Show, type Component } from 'solid-js'
import { QuoteFooterButton } from '../../../../components/Button/Buttons'
import { Next } from '../../../../components/PageRouter/Next'
import { FormattedPrice } from '../../../../components/Quote/Price/FormattedPrice'
import { getPermissions } from '../../../services/permissions/PermissionsController'
import { MIN_POLICIES, canQuote, useCompany, usePolicies, useStartDate } from '../../../store/quotes/quoteAndBuyStore'
import styles from './QuoteFooter.module.css'
import { Footer } from '../../../layouts/main-layout/Footer/Footer'

export const QuoteFooter: Component<{ price?: number }> = (props) => {
    const permissions = getPermissions()

    return (
        <Footer class={styles.Footer}>
            <div>
                <Show when={canQuote()}>
                    <Show when={props.price} fallback={<Spinner />}>
                        <FormattedPrice price={props.price} />
                    </Show>
                </Show>
            </div>
            <div>
                <Show when={permissions?.canShareQuote()}>
                    <ShareButton price={props.price} />
                </Show>
                <Show when={permissions?.canPurchaseQuote()}>
                    <NextButton />
                </Show>
            </div>
        </Footer>
    )
}

const ShareButton: Component<{ price?: number }> = (props) => {
    const navigate = useNavigate()

    const canShare = () => usePolicies().length >= MIN_POLICIES && !!(useCompany()) && !!(useStartDate()) && !!(props.price)
    const onShare = () => {
        if (!canShare()) return
        navigate('/quotes/new/contact')
    }

    return (
        <QuoteFooterButton disabled={!canShare()} onClick={onShare}>Share &rarr;</QuoteFooterButton>
    )
}

// next button is for the Purchase Quote flow
const NextButton: Component<{}> = () => {
    const isValid = () => {
        //TODO: check if all fields are valid for the policies
        //TODO: ensure we have a valid quote
        return true
    }

    return (
        <Next>
            <QuoteFooterButton disabled={!isValid()}>Next &rarr;</QuoteFooterButton>
        </Next>
    )
}
