import { type ParentComponent } from 'solid-js'
import { classList } from '@peachy/utility-kit-pure'
import styles from './Section.module.css'


const Section: ParentComponent<{ class?: string }> = (props) => {
    return (
        <section class={styles.Section}>
            <div class={classList(styles.Contents, props.class)}>
                {props.children}
            </div>
        </section>
    )
}

export const TextSection: ParentComponent = (props) => {
    return <Section class={styles.Text}>{props.children}</Section>
}

export const FormSection: ParentComponent = (props) => {
    return <Section class={styles.Form}>{props.children}</Section>
}

export const WideSection: ParentComponent = (props) => {
    return <Section class={styles.Wide}>{props.children}</Section>
}