import { Properties } from '@peachy/utility-kit-pure'
import { LifeEventData } from './notification-types'
import { AnEvent } from '@punnet/core-event-pure'

import { AccountType } from '../../domain/models/Account'


export class LifeActivatedNotification extends AnEvent implements LifeEventData {

    constructor(from: Properties<LifeActivatedNotification>) {
        super()
        Object.assign(this, from)
    }

    accountId: string
    accountType: AccountType
    subscriptionId: string
    policyId: string
    lifeId: string
    cognitoId: string
}
