import { formatDmyDate } from '@peachy/utility-kit-pure'
import type { Company } from '@punnet/subscription-pure'
import { Show, createSignal } from 'solid-js'
import { PlanBuilder } from '../../../../components/PlanBuilder/PlanBuilder'
import { SovereignDatePicker } from '../../../../components/SovereignDatePicker/SovereignDatePicker'
import { SovereignCompanyLookup } from '../../../../components/lookup/SovereignCompanyLookup'
import { getPermissions } from '../../../services/permissions/PermissionsController'
import { useCompany, useStartDate, useStoreActions } from '../../../store/quotes/quoteAndBuyStore'
import { Label, Title } from '../Quote'
import styles from './QuoteBuilder.module.css'

export function QuoteBuilder() {
    const permissions = getPermissions()

    return (
        <div class={styles.QuoteBuilder}>
            <Title>Quote builder:</Title>

            <div class={styles.DoubleGrid}>
                <div class={styles.StartDate}>
                    <Label>Start date</Label>
                    <StartDate />
                </div>
                <div class={styles.DownloadPDF}>
                    <Show when={permissions.canShareQuote()}>
                        <DownloadPDFLink />
                    </Show>
                </div>
            </div>

            <div>
                <PlanBuilder />
            </div>

            <Show when={permissions.canShareQuote()}>
                <Title>Company details:</Title>

                <div class={styles.DoubleGrid}> 
                    <CompanyDetails />
                </div>
            </Show>
        </div>
    )
}

const CompanyDetails = () => {
    const company = useCompany
    const { setCompany } = useStoreActions

    return (
        <div class={styles.CompanyDetails}>
            <Label>Company name</Label>
            <SovereignCompanyLookup initialSelection={company()} onSelect={(company: Company) => setCompany(company)} />
        </div>
    )
}


const StartDate = () => {
    const [isOpen, setIsOpen] = createSignal(false)
    const startDate = useStartDate
    const { setStartDate } = useStoreActions

    const [dateInputRef, setDateInputRef] = createSignal<HTMLInputElement>()

    const onSelect = (date: Date) => {
        setStartDate(date.getTime())
        setIsOpen(false)
    }

    //TODO: get this from somewhere
    const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
    const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1))


    return (
        <>
            <input 
                ref={setDateInputRef}
                type="text" 
                value={formatDmyDate(startDate())} 
                onClick={() => setIsOpen(true)} />

            {/* modal */}
            <SovereignDatePicker
                isOpen={isOpen()}
                onDismiss={() => setIsOpen(false)}
                minDate={minDate}
                maxDate={maxDate}
                selectedDate={new Date(startDate())}
                headerText="Select a plan start date"
                locatorElement={dateInputRef()}
                submitText="Submit"
                onSelect={onSelect}
            />
        </>
    )
}

const DownloadPDFLink = () => {
    return <>Download PDF</>
}