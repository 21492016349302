import {NOOP} from '@peachy/utility-kit-pure'
import {useLocation, useNavigate, useParams} from '@solidjs/router'
import {isFinite} from 'lodash-es'
import {createEffect, createSignal} from 'solid-js'
import type {PageRouterPath} from './PageRouterPath'

const [currentPageIndex, setCurrentPageIndex] = createSignal(0)
const [maxPageIndex, setMaxPageIndex] = createSignal(0)
const [routes, setRoutes] = createSignal([])
export const hasPreviousPage = () => currentPageIndex() > 0
export const hasNextPage = () => currentPageIndex() < maxPageIndex()
export const nextPage = () => (hasNextPage()) && setCurrentPageIndex(currentPageIndex() + 1)
export const previousPage = () => (hasPreviousPage()) && setCurrentPageIndex(currentPageIndex() - 1)


export interface PageRouterProps {
    routes: PageRouterPath[]
}

const replaceParams = (path: string) => {
    const params = useParams()
    return path.replace(/:(\w+)/g, (_, key) => params[key] || '')
}

export const toPage = (routes : PageRouterPath[], pathname: string) => {
    const pageIndex = routes.findIndex((route) => replaceParams(route.path) === pathname)
    isFinite(pageIndex) ? setCurrentPageIndex(pageIndex) : NOOP
}

function determineAccessibleRoute (props: PageRouterProps) {
    const { routes } = props

    const requestedRouteIndex = routes.findIndex((route) => replaceParams(route.path) === useLocation().pathname)

    // determine if the user can access the requested route - if not, find the first page that they CAN access
    const route = routes.slice(0, requestedRouteIndex+1).reverse().find((route) => route.canAccess())
    const index = routes.indexOf(route)

    setCurrentPageIndex(index !== -1 ? index : 0)
}

export function PageRouter (props: PageRouterProps) {
    const navigate = useNavigate()

    setRoutes(props.routes)
    setMaxPageIndex(routes().length - 1)

    determineAccessibleRoute(props)

    const currentPage = () => routes()[currentPageIndex()]

    //if the route has changed, navigate to it
    createEffect(() => {
        if (isFinite(currentPageIndex())) {
            const pathToNavigateTo = replaceParams(currentPage().path)
            navigate(pathToNavigateTo, { replace: true })
        }
    })

    return <>
        {currentPage().component}
    </>
}
