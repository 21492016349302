import {currencyFromPence, readableDate} from '@peachy/utility-kit-pure'
import {For, type Component} from 'solid-js'
import styles from './QuotesList.module.css'

export type Quote = {
    lastEdited?: number
    companyName: string
    postcode: string
    startDate?: number
    members?: number
    totalMonthlyPremium?: number
    status?: string
    companyReference?: string
    userAssigned?: string
}

export type QuotesListProps = {
    quotes?: Quote[]
}

export const QuotesList: Component<QuotesListProps> = (props) => {

    return (
        <div class={styles.Quotes}>
            <div class={styles.Header}>
                <div>Last edited</div>
                <div>Company name</div>
                <div>Postcode</div>
                <div>Start date</div>
                <div>Total members</div>
                <div>Monthly premium</div>
                <div>Quote stage</div>
                <div>Company reference</div>
                <div>Assigned to</div>
            </div>

            <div class={styles.Content}>
                <For each={props.quotes}>
                    {(quote) => <QuoteRow {...quote} />}
                </For>
            </div>
        </div>
    )
}


const QuoteRow: Component<Quote> = (props) => {
    return (
        <>
            <div>{readableDate(props.lastEdited)}</div>
            <div>{props.companyName}</div>
            <div>{props.postcode}</div>
            <div>{readableDate(props.startDate)}</div>
            <div>{props.members}</div>
            <div>{currencyFromPence(props.totalMonthlyPremium)}</div>
            <div>{props.status}</div>
            <div>{props.companyReference ?? 'N/A'}</div>
            <div>{props.userAssigned}</div>
        </>
    )
}
