import type {Accessor, Setter} from 'solid-js'
import {createSignal} from 'solid-js'
import {last} from '@peachy/utility-kit-pure'


/**
 * Creates a buffered signal with a specified initial value and buffer size.
 * instead of a regular setter, this signal returns a pushValue function
 * that allows new values to be queued up.
 *
 * The current value does not change until the buffer is advanced by calling
 * the advanceBuffer function.
 *
 * If the buffer is full, the last value in the buffer is replaced with the new value.
 * If the new value is the same as the last value in the buffer, the buffer is not changed.
 *
 * @param initialValue The initial value of the signal.
 * @param initialSize The maximum size of the buffer (default is 3).
 * @returns A tuple containing:
 *   - An accessor function to get the current value
 *   - A push function to add a new value to the buffer
 *   - An advance function that moves the buffer forward by one (currentValue is updated)
 *   - An accessor function to get the entire buffer
 *
 * The buffered signal maintains a list of values, allowing for operations
 * like throttling or debouncing while preserving intermediate states.
 */


export function createBufferedSignal<T>(initialValue: T, initialBufferSize: number = 3): BufferedSignal<T> {

    const [bufferSize, setBufferSize] = createSignal(initialBufferSize)
    const [buffer, setBuffer] = createSignal([initialValue])
    const advanceBuffer = () => {
        setBuffer((currentBuffer) => {
            if (currentBuffer.length > 1) {
                return currentBuffer.slice(1)
            }
            return currentBuffer
        })
    }

    const pushValue = (value: T) => {
        let pushed = false
        setBuffer(currentBuffer => {
            if (last(currentBuffer) === value) {
                return currentBuffer
            }
            const newBuffer = [...currentBuffer]
            if (newBuffer.length == bufferSize()) {
                newBuffer.pop()
            }
            if (last(newBuffer) !== value) {
                newBuffer.push(value)
                pushed = true
            }
            return newBuffer
        })
        return pushed
    }
    const accessValue = () => {
        return buffer()[0]
    }

    return [accessValue, pushValue, advanceBuffer, buffer, setBufferSize]
}

export type BufferedSignal<T> = [Accessor<T>, (value: T) => boolean, () => void, Accessor<T[]>, Setter<number>]
