import { Life, Policy } from "@punnet/subscription-pure"
import type { Draft } from "@peachy/utility-kit-pure"
import { updatePolicy } from "./updatePolicy"

// TODO-CC Maybe try with immer. I gave up

export function updateLife(policies: Draft<Policy>[], policyId: string, life: Draft<Life>): Draft<Policy>[] {
    const matchingPolicy = policies.find(p => p.id === policyId)

    return updatePolicy(policies, {
        ...matchingPolicy,
        lives: {
            ...matchingPolicy.lives,
            [life.id]: life
        }
    })
}