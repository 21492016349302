import styles from './Modal.module.css'

import { defaultFalse, defaultTrue } from '@peachy/utility-kit-pure'
import createFocusTrap from 'solid-focus-trap'
import { createEffect, createSignal, onCleanup, ParentProps } from 'solid-js'
import { Portal, Show } from 'solid-js/web'
import { withViewTransition } from '../../functions/withViewTransition'
import { onEscapeKeyWhile } from '../../signals/keyboard/KeyboardController'

export type ModalProps = ParentProps<{
    class?: string

    enableTransitions?: boolean // default false
    transitionMarkerClass?: string

    isOpen: boolean
    onDismiss?: () => void   

    dissmissOnBackgroundClick?: boolean // default true
    dissmissOnEscapeKey?: boolean // default true

    trapFocus?: boolean // default true 

    locatorElement?: HTMLElement
    // pass a function to locate the modal?
    // locator?: (locatorElement: HTMLDivElement) => {} 

}>



export function Modal(props: ModalProps) {

    const [isOpen, setIsOpen] = createSignal(false)

    createEffect(() => {
        if (props.isOpen) {
            
            if (!isOpen()) {
                console.log('modal opening');
                withViewTransition({
                    transitionMarkerClass: props.transitionMarkerClass,
                    condition: defaultFalse(props.enableTransitions),
                    updateDom: () => {
                        setIsOpen(true)
                    }
                })

                console.log('Should be open!', isOpen());

            }

        } else {
            if (isOpen()) {

                console.log('modal closing');

                withViewTransition({
                    transitionMarkerClass: props.transitionMarkerClass,
                    condition: defaultFalse(props.enableTransitions),
                    updateDom: () => {
                        setIsOpen(false)
                    }
                })
            }
        }
    })

    onEscapeKeyWhile(
        () => props.isOpen && defaultTrue(props.dissmissOnEscapeKey), 
        props.onDismiss
    )


    return (
        <Show when={isOpen()}>
            <Portal>
                <ModalContent {...props}/>
            </Portal>
        </Show>
    )
}

function ModalContent(props: ModalProps) {

    const [modalRef, setModalRef] = createSignal<HTMLElement>()
    const [locatorBounds, setLocatorBounds] = createSignal<DOMRect>()


    const locateModal = () => {
        const locatorBounds = props.locatorElement?.getBoundingClientRect()
        if (locatorBounds) {
            setLocatorBounds(locatorBounds)
            console.log
        }
    }

    createEffect(() => {
        locateModal()
    })

    createEffect(() => {
        if (props.locatorElement) {
            window.addEventListener('resize', locateModal)
            window.addEventListener('scroll', locateModal)
            onCleanup(() => {
                window.removeEventListener('resize', locateModal)
                window.removeEventListener('scroll', locateModal)
            })
        }
    })

    const positioningStyle = () => {
        return locatorBounds() ? {
            position: 'absolute',
            top: `${locatorBounds().bottom}px`,
            left: `${locatorBounds().left}px`,
        }: {} as any
    }

    createFocusTrap({ 
        element: modalRef, 
        enabled: () => props.isOpen && defaultTrue(props.trapFocus)
    })

    const onBackgroundClick = (e: MouseEvent) => {
        if (defaultTrue(props.dissmissOnBackgroundClick)) {
            props.onDismiss?.()
            e.stopPropagation()
        }
    }

    return (
        <div class={styles.ModalPane} data-modal-pane onClick={onBackgroundClick}>
            <aside onClick={e => e.stopPropagation()} class={props.class} ref={setModalRef} style={positioningStyle()}>
                {props.children}
            </aside>
        </div>
    )
}

