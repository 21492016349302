import { ParentComponent } from 'solid-js'
import { classList } from '@peachy/utility-kit-pure'
import styles from './Card.module.css'

type CardProps = {
    class?: string
}

export const Card: ParentComponent<CardProps> = (props) => {
    return (
        <div class={classList(styles.Card, 'PlanConfigurationCard', props.class)}>
            {props.children}
        </div>
    )
}