import {keysOf} from '@peachy/utility-kit-pure'
import { prefixWith } from '@punnet/alterations-pure'


export const BasicAlterationTypes = [
    'ADDED',
    'REMOVED',
    'ALTERED'
] as const


export type BasicAlterationType = typeof BasicAlterationTypes[number]


// applies to everything except Plans and PlanBenefits
const LifecycleAlterationTypes = [
    'ADDED',
    'ALTERED',
    'RENEWED',
    'TRANSFERRED-IN',
    'TRANSFERRED-OUT',
    'CANCELLED',
    'REACTIVATED',
] as const


// applies to PlanBenefits and LifeBenefits
export const BenefitPropertyAlterations = [
    'LIMIT-INCREASED',
    'LIMIT-DECREASED',
    'EXCESS-INCREASED',
    'EXCESS-DECREASED',
] as const

// applies to Lives only
const LifePropertyAlterations = [
    'ADDRESS-UPDATED',
    'BIRTHDATE-UPDATED',
    'PLAN-SWITCHED',
] as const


// exported alterations

export const LifeBenefitAlterations = [
    ...prefixWith('LIFE-BENEFIT', [
        ...LifecycleAlterationTypes,
        ...BenefitPropertyAlterations
    ] as const),
] as const


export const LifeAlterations = [
    ...prefixWith('LIFE', [
        ...LifecycleAlterationTypes,
        ...LifePropertyAlterations,
    ] as const),
] as const

export const PolicyAlterations = [
    ...prefixWith('POLICY', [
        ...LifecycleAlterationTypes,
        ...prefixWith('PLAN', [
            ...BasicAlterationTypes,
            ...prefixWith('BENEFIT', [
                ...BasicAlterationTypes,
                ...BenefitPropertyAlterations
            ] as const)
        ] as const)
    ] as const),
] as const


export const SubscriptionAlterations = [
    ...prefixWith('SUBSCRIPTION', [
        ...LifecycleAlterationTypes,
        ...prefixWith('PLAN', [
            ...BasicAlterationTypes,
            ...prefixWith('BENEFIT', [
                ...BasicAlterationTypes,
                ...BenefitPropertyAlterations
            ] as const)
        ] as const)
    ] as const),
] as const

export const AccountAlterations = [
    ...prefixWith('ACCOUNT', [
        ...LifecycleAlterationTypes,
    ] as const),
] as const

export const AllAlterations = [
    ...AccountAlterations,
    ...SubscriptionAlterations,
    ...PolicyAlterations,
    ...LifeAlterations,
    ...LifeBenefitAlterations,
]


// alteration types

export type AccountAlterationType = typeof AccountAlterations[number]
export type SubscriptionAlterationType = typeof SubscriptionAlterations[number]
export type PolicyAlterationType = typeof PolicyAlterations[number]
export type LifeAlterationType = typeof LifeAlterations[number]
export type LifeBenefitAlterationType = typeof LifeBenefitAlterations[number]


export type AlterationType =

    | AccountAlterationType
    | SubscriptionAlterationType
    | PolicyAlterationType
    | LifeAlterationType
    | LifeBenefitAlterationType


// alteration namespaces


export const AvailableBenefitAlteration = keysOf<AccountAlterationType>()

export const AvailableAccountAlteration = keysOf<AccountAlterationType>()
export const AvailableSubscriptionAlteration = keysOf<SubscriptionAlterationType>()
export const AvailablePolicyAlteration = keysOf<PolicyAlterationType>()
export const AvailableLifeAlteration = keysOf<LifeAlterationType>()
export const AvailableLifeBenefitAlteration = keysOf<LifeBenefitAlterationType>()
export const AvailableAlteration = keysOf<AlterationType>()


