import type { DateTime, Pence } from '@peachy/core-domain-pure'
import { pick } from '@peachy/utility-kit-pure'

export type PaymentTransactionStatus =
    | 'SUCCESS'
    | 'FAIL'

//TODO: Check hasMore field
//TODO: Consider how we want to display in bdx now that we have info at line item level
// - would we record the prorations as payments against the different periods?
// - if we don't want this, we will probably need to record extra info to determine which invoice period it was bundled with
//TODO: Does it matter that a refund will be a credit on their account until they cancel?
// - it may mean that the bdx doesn't reconcile 100%, eg. the transactions (line items) would suggest money has been sent when it technically hasn't moved
// - we can store the endingBalance on this object, or we could track the customers balance separately
// - we could create additional transactions representing money moving in and out of the customer's balance account in order to balance these out in the bdx


export class PaymentProviderTransaction {
    eventId: string
    invoiceId: string
}

export class PaymentTransaction {
    id: string
    accountId: string
    subscriptionId: string
    paymentProvider: PaymentProviderTransaction
    occurredAt: DateTime
    status: PaymentTransactionStatus
    subtotal: Pence
    total: Pence
    amountDue: Pence
    amountPaid: Pence
    amountRemaining: Pence
    periodStart: DateTime
    periodEnd: DateTime
    lineItems: PaymentLineItem[]
    endingBalance: Pence
    promo?: string
    failureReason: string
    failureCode: string
}

export class PaymentProviderLineItem {
    lineItemId: string
    description: string
}

export class PaymentLineItem {
    id: string // TODO: Do we need a unique id at this level?
    paymentProvider: PaymentProviderLineItem
    periodStart: DateTime
    periodEnd: DateTime
    chargeAmount: Pence
    discountAmount: Pence
    proration: boolean
}

/**
 * How much money was actually exchanged? If the invoice total was positive, we can use the amount paid field,
 * however in the case of a refund, amount due and amount paid will be 0, so use the total instead (in this case the
 * money will only be transferred to the customer's balance, only being refunded to card in event of cancellation).
 */
export function getAmountTransferred(payment: PaymentTransaction): Pence {
    if (payment.status == 'FAIL') {
        return 0
    } else {
        if (payment.total < 0) {
            return payment.total
        } else {
            return payment.amountPaid
        }
    }
}


export function paymentSummary(forPayment: PaymentTransaction) {
    return pick(forPayment, 'subscriptionId', 'amountDue', 'status', 'amountPaid', 'occurredAt', 'periodStart', 'periodEnd')
}
