import { type Draft, newUUID } from '@peachy/utility-kit-pure'
import { Life, Policy } from '@punnet/subscription-pure'

import { createLife } from './createLife'


export function createPolicy (life: Draft<Life>, startDate?: number) {
    return {
        id: newUUID(),
        lives: {
            [life.id]: life
        },
        startDate
    } as Draft<Policy>
}

export function createDraftPolicy(policyStartDate: number, planId: string): Draft<Policy> {
    return createPolicy(createLife({ planId: planId, type: 'PRIMARY' }), policyStartDate)
}
