import { Suspense, createResource, type ParentComponent } from 'solid-js'
import { RouteTransitionLink } from '@punnet/web-client-kit-library'
import { GreyButton } from '../../../components/Button/Buttons'
import { QuotesList } from '../../../components/Quote/List/QuotesList'
import { useSubscriptionService } from '@punnet/subscription-web-client'
import { type Quote } from '../../../components/Quote/List/QuotesList'
import type { SubscriptionQuoteResponse } from '@punnet/subscription-pure'
import { Pulse } from '../../../components/Pulse/Pulse'
import styles from './Quotes.module.css'


//TODO: this is a temporary mapping function to get the quotes page working - the data will be mapped and provided by the backend soon...
const mapQuoteResponse = (quoteResponse: SubscriptionQuoteResponse[]): Quote[] => {
    return quoteResponse.map(quote => ({
        lastEdited: undefined,
        companyName: quote.account?.company?.name,
        postcode: quote.account?.company?.address?.postcode,
        startDate: quote.subscription?.startDate,
        members: quote.policies?.length,
        totalMonthlyPremium: quote.totalMonthlyPremium,
        status: undefined, 
        companyReference: quote.account?.company?.id,
        userAssigned: undefined
    } as Quote))
}

export const Quotes: ParentComponent = () => {
    const subscriptionService = useSubscriptionService()

    const [quotes] = createResource(async () => {
        const quotesResponse = await subscriptionService.loadSmeDraftQuote({ quoteId: 'all' })
        return mapQuoteResponse(quotesResponse)
    })

    return (
        <div class={styles.Quotes}>
            <div>
                <h1>QUOTES</h1>
            </div>

            <div>
                <RouteTransitionLink href="/quotes/new">
                    <GreyButton>New quote</GreyButton>
                </RouteTransitionLink>
            </div>

            <div>
                <Suspense fallback={<Pulse isShown={true} />}>
                    <QuotesList quotes={quotes()}/>
                </Suspense>
            </div>
        </div>
    )
}

