import { AnEvent } from '@punnet/core-event-pure'

import { KeyMapped, Properties } from '@peachy/utility-kit-pure'

import type { AccountType } from '../../domain/models/Account'
import type { Plan } from '../../domain/models/Plan'
import type { Policy } from '../../domain/models/Policy'
import type { LifeEventData } from '../notifications/notification-types'


export class LifeRepoUpsertRequest extends AnEvent implements LifeEventData {

    accountId: string
    accountType: AccountType
    subscriptionId: string
    policyId: string
    lifeId: string
    plans: KeyMapped<Plan>
    cognitoId: string

    policy: Policy
    subscriptionStartDate: number

    constructor(from: Properties<LifeRepoUpsertRequest>) {
        super()
        Object.assign(this, from)
    }
}
