import { mapById, values } from '@peachy/utility-kit-pure'

import { Life } from '../../../models/Life'
import { LifeBenefit } from '../../../models/LifeBenefit'
import { isCancelled } from '../../../models/LifecycleStatus'
import { Policy } from '../../../models/Policy'
import { Subscription } from '../../../models/Subscription'

export function renewSubscription(
    subscription: Subscription,
    effectiveDate: number,
    renewalPeriodMonths: number
): Subscription {

    if (isCancelled(subscription)) {
        return subscription
    }

    const renewedSubscription: Subscription = {...subscription}

    const renewedPolicies = values(renewedSubscription.policies)
        .filter(p => !isCancelled(p))
        .map(p => renewPolicy(p, effectiveDate))

    delete renewedSubscription.totalMonthlyPremium

    // renewedSubscription.nextRenewalDate

    renewedSubscription.policies = {
        ...renewedSubscription.policies,
        ...mapById(renewedPolicies)
    }

    return renewedSubscription
}


export function renewPolicy(
    policy: Policy,
    effectiveDate: number,
): Policy {

    if (isCancelled(policy)) {
        return policy
    }

    const renewedPolicy: Policy = {...policy}

    const renewedLives = values(renewedPolicy.lives)
        .filter(l => !isCancelled(l))
        .map(l => renewLife(l, effectiveDate))

    delete renewedPolicy.totalMonthlyPremium
    renewedPolicy.lives = {
        ...renewedPolicy.lives,
        ...mapById(renewedLives)
    }

    return renewedPolicy
}



export function renewLife(
    life: Life,
    effectiveDate: number,

): Life {

    if (isCancelled(life)) {
        return life
    }

    const renewedLife: Life = {...life}

    const renewedBenefits = values(renewedLife.benefits)
        .filter(b => !isCancelled(b))
        .map(b => renewLifeBenefit(b, effectiveDate))


    delete renewedLife.totalMonthlyPremium

    renewedLife.benefits = {
        ...renewedLife.benefits,
        ...mapById(renewedBenefits)
    }

    return renewedLife
}

export function renewLifeBenefit(
    benefit: LifeBenefit,
    effectiveDate: number,
): LifeBenefit {

    if (isCancelled(benefit)) {
        return benefit
    }

    const renewedBenefit: LifeBenefit = {...benefit}
    renewedBenefit.effectiveDate = effectiveDate
    delete renewedBenefit.premium

    return renewedBenefit
}
