import { createSignal } from 'solid-js'
import { Title } from '../Quote'
import { FormSection, TextSection } from '../Section'
import { CompanySchemeHeader } from './CompanySchemeHeader'
import { NavigationButtons } from './NavigationButtons'
import styles from '../Quote.module.css'

type WelcomeEmailSchedule = 'now' | 'before-start-date' | undefined

// state is here for now so data is persisted between page transitions
const [sendWelcomeEmail, setSendWelcomeEmail] = createSignal<WelcomeEmailSchedule>(undefined)

export const Schedules = () => {
    const isValid = () => sendWelcomeEmail() !== undefined

    return <>
        <TextSection>
            <CompanySchemeHeader />
            <p>Set your preference on when to send out the welcome email and documents to your employees</p>
        </TextSection>
        
        <FormSection>
            <Title>Employee welcome email:</Title>

            <div class={styles.Section}>
                <label>
                    <input required type="radio" name="scheduleEmail" value={'now'} checked={sendWelcomeEmail() === 'now'} onChange={() => setSendWelcomeEmail('now')} />
                    Send welcome email now
                </label>
                <label>
                    <input required type="radio" name="scheduleEmail" value={'before-start-date'} checked={sendWelcomeEmail() === 'before-start-date'} onChange={() => setSendWelcomeEmail('before-start-date')} />
                    Send welcome email 3-5 days before company scheme start date
                </label>
            </div>
        </FormSection>

        <TextSection>
            <NavigationButtons nextButtonDisabled={!isValid()} />
        </TextSection>
    </>
}