import { Title } from '../Quote'
import { NavigationButtons } from './NavigationButtons'
import styles from '../Quote.module.css'
import { FormSection, TextSection } from '../Section'
import { CompanySchemeHeader } from './CompanySchemeHeader'
import { createSignal } from 'solid-js'

// state is here for now so data is persisted between page transitions
const [authority, setAuthority] = createSignal<boolean | undefined>(undefined)

export const DirectDebitAuthority = () => {
    const isValid = () => authority() !== undefined

    return <>
        <TextSection>
            <CompanySchemeHeader />
            <p>Add your company direct debit details.</p>
            <p>You can add details for a finance contact to add Direct Debit details if you do not have direct debit authority.</p>
        </TextSection>
        
        <FormSection>
            <Title>Payment:</Title>

            <div class={styles.Section}>
                <p>Do you have Direct Debit authority?</p>
                <label>
                    <input required type="radio" name="directDebitAuthority" value={'yes'} checked={authority() === true} onChange={() => setAuthority(true)}/>
                    Yes
                </label>
                <label>
                    <input required type="radio" name="directDebitAuthority" value={'no'} checked={authority() === false} onChange={() => setAuthority(false)}/>
                    No
                </label>
            </div>
        </FormSection>

        <TextSection>
            <NavigationButtons nextButtonDisabled={!isValid()} />
        </TextSection>
    </>
}