
export class Permissions {
    private isBroker: boolean

    constructor (private readonly token: unknown) {
        this.isBroker = this.#isBroker()
    }

    canShareQuote() {
        return this.isBroker
    }

    canPurchaseQuote() {
        return !this.isBroker
    }

    canEditEmployeeDetails() {
        return !this.isBroker
    }

    canEditEmployeeCounts() {
        return this.isBroker
    }

    canViewQuotes() {
        return this.isBroker
    }

    #isBroker(): boolean {
        const emailIsBroker = this.token?.email?.includes('+bdm')
        return emailIsBroker ?? false
    }
}
