export * from './src/IamService'
export * from './src/IamApi'
export * from './src/IamStateMachine'
export * from './src/components/IamController'


export * from './src/components/IamPanel'

        import * as me from '@punnet/iam-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/codebuild/output/src1135346220/src/codestar-connections.eu-west-2.amazonaws.com/git-http/730335353981/eu-west-2/ec6127c2-3e3a-4fab-b1a9-b6df4376a30f/peachy-punnet/peachy-mono-repo/punnet/stacks/iam/client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@punnet/iam-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    