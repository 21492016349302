import type { ParentComponent } from 'solid-js'
import { PrimaryButton } from '../../../../components/Button/Buttons'
import { Next } from '../../../../components/PageRouter/Next'
import { Previous } from '../../../../components/PageRouter/Previous'
import styles from './NavigationButtons.module.css'

type NavigationButtonsProps = {
    nextButtonDisabled?: boolean
    nextButtonText?: string
}

export const NavigationButtons: ParentComponent<NavigationButtonsProps> = (props) => {
    return <div class={styles.Buttons}>
        <Next>
            <PrimaryButton disabled={props.nextButtonDisabled}>{props.nextButtonText ?? 'Next'}</PrimaryButton>
        </Next>
        {props.children}
    </div>
}

export const PreviousButton = () => <Previous><div class={styles.PreviousButton}><i class="fal fa-angle-left" /></div></Previous>
