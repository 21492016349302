import {Modal} from '../Modal/Modal'
import {Component} from 'solid-js'
import { classList } from '@peachy/utility-kit-pure'
import styles from './Pulse.module.css'

export type PulseProps = {
    isShown: boolean
    dotClassName?: string
}

export const Pulse: Component<PulseProps> = (props) => {
    const Dot = () => <div class={classList(styles.Dot, props.dotClassName)}></div>

    return (
        <Modal isOpen={props.isShown} class={styles.Modal}>
            <div class={styles.Pulse}>
                <Dot />
                <Dot />
                <Dot />
            </div>
        </Modal>
    )
}
