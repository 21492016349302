import { Address } from '@punnet/subscription-pure'
import { type Component } from 'solid-js'
import { useManageStoreActions } from '../../../../app/store/quotes/manageEmployeesStore'
import { SovereignAddressLookup } from '../../../lookup/SovereignAddressLookup'
import { type LifeRowCellProps } from '../ManageEmployeesTable'

export const AddressCell: Component<LifeRowCellProps> = (props) => {
    const { updateLife } = useManageStoreActions()

    const initialAddress = () => props.life?.address?.display ?? ''

    // TODO-DP looking into a spinner for when loading the address
    const onSelectAddress = (address: Address) => {
        updateLife(props.policy.id, {
            ...props.life,
            address: address,
            postcode: address.postcode
        })
    }

    const onBlur = (value: string) => {
        if (!value) {
            updateLife(props.policy.id, {
                ...props.life,
                address: undefined,
                postcode: undefined
            })
        }
    }

    return <td>
        <SovereignAddressLookup
            initialValue={initialAddress()}
            onSelect={onSelectAddress}
            onBlur={onBlur}
        />
    </td>
}
