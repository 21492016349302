import { Show, type Component } from 'solid-js'
import { useManageStoreActions } from '../../../../app/store/quotes/manageEmployeesStore'
import type { LifeRowCellProps } from '../ManageEmployeesTable'
import tableStyles from '../ManageEmployeesTable.module.css'

export const RemovePolicy: Component<LifeRowCellProps> = (props) => {
    const { removePolicy, canRemovePolicy } = useManageStoreActions()
    
    const onRemove = () => {
        removePolicy(props.policy)
    }

    return <td class={tableStyles.delete}>
        <Show when={canRemovePolicy()}>
            <button onClick={onRemove}>
                <i class="fas fa-trash" />
            </button>
        </Show>
    </td>
}
