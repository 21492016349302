import { createToggleSignal, TextBox } from '@punnet/web-client-kit-library'
import { type Component } from 'solid-js'
import { useManageLifeValidation, useManageStoreActions } from '../../../../app/store/quotes/manageEmployeesStore'
import type { LifeRowCellProps } from '../ManageEmployeesTable'
import { TableCell } from './TableCell'


export const FirstNameCell: Component<LifeRowCellProps> = (props) => {
    const { updateLife } = useManageStoreActions()
    const { firstName } = useManageLifeValidation()

    const isValid = () => firstName(props.life)
    const [isFocus, setIsFocus] = createToggleSignal(false)

    return <TableCell>
        <TextBox value={props.life.firstname ?? ''}
            onInput={firstname => updateLife(props.policy.id, { ...props.life, firstname: firstname.trim() })}
            placeholder={'First name'}
            onFocus={setIsFocus}
            onBlur={setIsFocus}
            inputClass={isFocus() || isValid() ? '' : props.errorStyles}
        />
    </TableCell>
}
