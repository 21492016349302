import { mapToPlanBenefits, type PlanConfig } from '@punnet/product-client'
import { Plan } from '@punnet/subscription-pure'
import { newUUID } from '@peachy/utility-kit-pure'

export function createPlans(planConfig: PlanConfig): Plan[] {
    return planConfig.getAllPlans().map((planConfig) => ({
        id: newUUID(),
        configId: planConfig.id,
        name: planConfig.name,
        benefits: mapToPlanBenefits(planConfig.benefits)
    } as Plan))
}
