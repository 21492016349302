import {isCancelled, Life, LifeBenefit, Policy} from '@punnet/subscription-pure'
import {Draft, KeyMapped, keys, mapById, values} from '@peachy/utility-kit-pure'


export function selectPoliciesForQuote(policies: KeyMapped<Policy>, diff: Draft<KeyMapped<Policy>>): KeyMapped<Policy> {
    return mapById(
        keys(diff)
            .map(policyId => policies[policyId])
            .filter(p => !isCancelled(p))
            .map(p => {
                return {
                    ...p,
                    lives: selectLivesForQuote(p.lives, diff[p.id]?.lives)
                }
            })
    )
}


export function selectLivesForQuote(newLifeMap: KeyMapped<Life>, diff: Draft<KeyMapped<Life>>): KeyMapped<Life> {
    return mapById(
        keys(diff)
            .map(lifeId => newLifeMap[lifeId])
            .filter(l => !isCancelled(l))
            .map(l => {
                return {
                    ...l,
                    benefits: selectBenefitsForQuote(l.benefits)
                }
            })
    )
}


export function selectBenefitsForQuote(newBenefits: KeyMapped<LifeBenefit>): KeyMapped<LifeBenefit> {
    return mapById(values(newBenefits).filter(b => !isCancelled(b)))
}
