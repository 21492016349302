import { Properties } from '@peachy/utility-kit-pure'
import { AnEvent } from '@punnet/core-event-pure'

import { CancellationEventData, SubscriptionEventData } from './notification-types'
import { AccountType } from '../../domain/models/Account'
import { CancellationType } from '../../domain/models/CancellationType'

export class SubscriptionCancelledNotification extends AnEvent implements SubscriptionEventData, CancellationEventData {
    constructor(from: Properties<SubscriptionCancelledNotification>) {
        super()
        Object.assign(this, from)
    }

    accountId: string
    accountType: AccountType
    subscriptionId: string
    cancellationReason: string
    cancellationType: CancellationType
}
