
export class InviteUserRequest {
    email: string
    firstName: string
    lastName: string
}

export class InviteUserResponse {
    id: string
}

export class InviteUserErrorResponse {
    message: string
}


export type CognitoUserAttributes<T = {}> = T & {
    email: string
    email_verified: boolean
    phone_number?: string
    phone_number_verified: boolean
    given_name?: string
    family_name?: string
    sub: string
    'cognito:username': string
}


export type JwtClaims<T extends CognitoUserAttributes> = T & {
    aud: string
    auth_time: string
    event_id: string
    exp: string
    iat: string
    iss: string
    jti: string
    origin_jti: string
    token_use: 'id' | 'access'
}



// export type CognitoMemberUserAttributes = CognitoUserAttributes<{
//     'custom:initial_phone': string
//     'custom:intercom_id': string
//     'custom:accountId': string
//     'custom:subscriptionId': string
//     'custom:policyId': string
//     'custom:lifeId': string
//     'custom:content_bucket_path': string
// }>

// export type CognitoPortalUserAttributes = CognitoUserAttributes<{
//     'custom:accountId': string
//     'custom:intercom_id': string
//     'custom:subscriptionId': string
// }>


