
import type { Draft } from '@peachy/utility-kit-pure'
import { Life, Policy } from '@punnet/subscription-pure'
import { createMemo, For, type Component } from 'solid-js'
import { useManagePolicies } from '../../../app/store/quotes/manageEmployeesStore'

import { AddressCell } from './cell/AddressCell'
import { BirthDateCell } from './cell/BirthDateCell'
import { EmailCell } from './cell/EmailCell'
import { FirstNameCell } from './cell/FirstNameCell'
import { LastNameCell } from './cell/LastNameCell'
import { PlanSelector } from './cell/PlanSelector'
import { RemovePolicy } from './cell/RemovePolicy'
import styles from './ManageEmployeesTable.module.css'


export const ManageEmployeesTable: Component = () => {
    const policies = useManagePolicies()

    return (
        <table class={styles.table}>
            <thead>
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Date Of Birth</th>
                    <th>Address</th>
                    <th>Email</th>
                    <th>Plan</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <For each={policies}>{(policy) => <PolicyRow policy={policy} />}</For>
            </tbody>
        </table>
    )
}

const PolicyRow: Component<{ policy: Draft<Policy> }> = (props) => {

    const cellProps = createMemo(() => ({
        life: Object.values(props.policy.lives)[0],
        policy: props.policy,
        errorStyles: styles.errorCell
    }))

    return (
        <tr>
            <FirstNameCell {...cellProps()} />
            <LastNameCell {...cellProps()} />
            <BirthDateCell {...cellProps()} />
            <AddressCell {...cellProps()} />
            <EmailCell {...cellProps()} />
            <PlanSelector {...cellProps()} />
            <RemovePolicy {...cellProps()} />
        </tr>
    )
}

export type LifeRowCellProps = {
    life?: Draft<Life>
    policy: Draft<Policy>
    errorStyles: string
}
