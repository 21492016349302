import { Properties } from '@peachy/utility-kit-pure'

import { LifeTurnover, SubscriptionEventData } from './notification-types'
import { AccountType } from '../../domain/models/Account'
import { AnEvent } from '@punnet/core-event-pure'



export class SubscriptionAlteredNotification extends AnEvent implements SubscriptionEventData {
    constructor(from: Properties<SubscriptionAlteredNotification>) {
        super()
        Object.assign(this, from)
    }


    lifeTurnover?: LifeTurnover

    accountId: string
    accountType: AccountType
    subscriptionId: string
}
