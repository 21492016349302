import { useIamService } from '@punnet/iam-client'
import type { SubscriptionQuoteRequest } from '@punnet/subscription-pure'
import { useSubscriptionService } from '@punnet/subscription-web-client'
import { Show, createContext, createEffect, createResource, useContext, type ParentComponent } from 'solid-js'
import { Pulse } from '../../components/Pulse/Pulse'
import { getPermissions } from './permissions/PermissionsController'


type AccountData = {
    latestQuote?: SubscriptionQuoteRequest
}

const AccountContext = createContext<AccountData>()

async function loadLatestQuote() {
    const permissions = getPermissions()

    if (permissions.canPurchaseQuote()) {
        return useSubscriptionService().latestSmeDraftQuote()
    }
}

async function loadAccountData() {
    let latestQuote: SubscriptionQuoteRequest | undefined

    try {
        latestQuote = await loadLatestQuote()
    } catch (error) {
        //expected if no draft quote exists
    }

    return { latestQuote }
}

export const AccountProvider: ParentComponent = (props) => {
    const iamService = useIamService()

    const [accountData, { mutate: setAccountData }] = createResource(() => iamService.isSignedIn(), loadAccountData)

    createEffect(() => {
        if (!iamService.isSignedIn()) {
            setAccountData()
        }
    })

    return <>
        <Show when={!accountData.loading} fallback={<Pulse isShown={true} />}>
            <AccountContext.Provider value={accountData()}>
                {props.children}
            </AccountContext.Provider>
        </Show>
    </>
}

export const getAccountContext = () => useContext(AccountContext)

export function getLatestQuote() {
    const accountData = getAccountContext()
    return accountData?.latestQuote
}
