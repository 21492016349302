import {createStore, SetStoreFunction, Store} from 'solid-js/store'
import {
    areDatesEqual,
    clampMonthYear,
    MonthYear,
    monthYearAfter,
    monthYearBefore,
    noTime,
    toMonthYear
} from './date-kit'

import {CalenderMonthArray} from '@peachy/utility-kit-pure'
import {clamp} from 'date-fns'

type PickerState = {
    minDate: Date,
    maxDate: Date,
    monthYear: MonthYear
    initialDate: Date
    selectedDate: Date
}

export class DatePickerStore {

    private pickerState: Store<PickerState>
    private updatePickerState: SetStoreFunction<PickerState>

    constructor(minDate: Date, maxDate: Date, private initialDate: Date) {
        [this.pickerState, this.updatePickerState] = createStore<PickerState>({
            minDate: noTime(minDate),
            maxDate: noTime(maxDate),
            initialDate: noTime(initialDate),
            selectedDate: noTime(initialDate),
            monthYear: toMonthYear(initialDate)
        })
    }


    
    resetSelection() {
        this.selectedDate = this.initialDate
    }

    get selectedDate() {
        return this.pickerState.selectedDate
    }
    set selectedDate(date: Date) {
        const clampedDate = clamp(noTime(date), {start: this.minDate, end: this.maxDate})
        if (!areDatesEqual(this.selectedDate, clampedDate)) {
            this.updatePickerState('selectedDate', clampedDate)
        }
    }

    get monthYear() {
        return this.pickerState.monthYear
    }

    set monthYear(monthYear: MonthYear) {
        this.updatePickerState('monthYear', clampMonthYear(monthYear, this.minDate, this.maxDate))
    }


    get minDate() {
        return this.pickerState.minDate
    }

    set minDate(minDate: Date) {
        minDate = noTime(minDate)
        if (!areDatesEqual(this.minDate, minDate)) {
            this.updatePickerState('minDate', minDate)
            // will clamp to min/max
            // eslint-disable-next-line no-self-assign
            this.selectedDate = this.selectedDate
            // eslint-disable-next-line no-self-assign
            this.monthYear = this.monthYear
        }
    }

    get maxDate() {
        return this.pickerState.maxDate
    }

    set maxDate(maxDate: Date) {
        maxDate = noTime(maxDate)
        if (!areDatesEqual(this.maxDate, maxDate)) {
            this.updatePickerState('maxDate', maxDate)
            // will clamp to min/max
            // eslint-disable-next-line no-self-assign
            this.selectedDate = this.selectedDate
            // eslint-disable-next-line no-self-assign
            this.monthYear = this.monthYear
        }
    }

    get year() {
        const [_month, year] = this.monthYear
        return year
    }
    set year(newYear: number) {
        this.monthYear = [this.month, newYear]
    }

    get month() {
        const [month, _year] = this.monthYear
        return month
    }
    set month(newMonth: number) {
        this.monthYear = [newMonth, this.year]
    }

    nudgeMonthEarlier() {
        this.monthYear = monthYearBefore(this.monthYear)
    }

    nudgeMonthLater() {
        this.monthYear = monthYearAfter(this.monthYear)
    }

    nudgeYearEarlier() {
        const [month, year] = this.monthYear
        this.monthYear = [month, year-1]
    }

    nudgeYearLater() {
        const [month, year] = this.monthYear
        this.monthYear =  [month, year+1]
    }

    get displayMonth() {
        return CalenderMonthArray[this.month-1]
    }
}
