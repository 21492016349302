import {classList} from '@peachy/utility-kit-pure'
import styles from './Spinner.module.css'

type SpinnerProps = {
    class?: string
}

export function Spinner (props: SpinnerProps) {
    const classes = classList(styles.spinner, 'peachy-spinner fa-duotone fa-spinner-third', props.class)
    return <i class={classes}></i>
}
