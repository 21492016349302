import { QuoteBuilder } from '../QuoteBuilder/QuoteBuilder'
import { useQuoteResponse } from '../QuoteController/QuoteController'
import { QuoteFooter } from '../QuoteFooter/QuoteFooter'
import styles from './PlanDetails.module.css'

export const PlanDetails = () => {

    const quoteResponse = useQuoteResponse()


    return (<>
        <section class={styles.QuoteBuilder}>
            <QuoteBuilder />
        </section>
        <QuoteFooter price={quoteResponse()?.totalMonthlyPremium} />
    </>)
}