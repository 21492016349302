import {Optional} from '@peachy/utility-kit-pure'
import * as PlanConfigJson from '@punnet/product-pure'
import {Benefit, Excess, Plan} from './model'

export class PlanConfig {

    constructor(private readonly config: PlanConfigJson.Config) {}

    getAllPlans (): Plan[] {
        const config = this.config

        return config.plans.map((plan) => ({
                id: plan.id,
                name: plan.name,
                benefits: this.mapBenefits(plan),
                excess: this.mapExcess(plan)
            } as Plan)
        )
    }

    getPlan (id: string): Plan {
        return this.getAllPlans().find(plan => plan.id === id)
    }

    getAllBenefits (): Benefit[] {
        return this.getAllPlans()[0].benefits
    }

    getBenefitForPlan (id: string, benefitId: string): Benefit {
        return this.getPlan(id).benefits.find(benefit => benefit.id === benefitId)
    }

    getBenefitTypesForPlan (id: string, benefitId: string): string[] {
        return this.getBenefitForPlan(id, benefitId).benefitTypes
    }

    private mapBenefits (plan: PlanConfigJson.Plan) {
        return this.config.benefits.map((benefitDefn) => {
            const availableBenefit = plan.availableBenefits[benefitDefn.id] || {} as PlanConfigJson.AvailableBenefit

            const appliedBenefit = {
                ...benefitDefn,
                ...availableBenefit
            }

            const isSupported = appliedBenefit.isEditable !== undefined

            return {
                ...appliedBenefit,
                isSupported,
                isActive: isSupported && appliedBenefit.include !== false
            }
        })
    }

    private mapExcess (plan: PlanConfigJson.Plan): Optional<Excess> {
        if(plan.excess?.id) {
            const excess: PlanConfigJson.Excess = this.config.excess[plan.excess.id]

            return {
                ...excess,
                ...plan.excess
            } as Excess
        }
    }
}
