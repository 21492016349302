import { newUUID, type Draft } from '@peachy/utility-kit-pure'
import { Address, Life, type LifeType } from '@punnet/subscription-pure'

type CreateLifeArgs = {
    firstname?: string
    lastname?: string
    dateOfBirth?: number
    address?: Address
    email?: string

    planId: string
    type: LifeType
}

export function createLife(args: CreateLifeArgs) {
    return {
        id: newUUID(),
        firstname: args.firstname ?? '',
        lastname: args.lastname ?? '',
        dateOfBirth: args.dateOfBirth ?? null,
        address: args.address ?? null,
        postcode: args?.address?.postcode ?? '',
        email: args.email ?? '',
        type: args.type,
        status: 'ACTIVE',
        planId: args.planId,
    } as Draft<Life>
}