import {defineValidator, MessageProducer, ValidateIf} from '../validation-core'
import {isArray, isString} from '@peachy/utility-kit-pure'

export default function MinLength<T>(
    minLength: number,
    message?: string | MessageProducer<T>,
    validateIf?: ValidateIf<T>
) {
    return defineValidator<T>({
        name: 'MinLength',
        message:
            message ??
            (({property}) =>
                `Property ${property.toString()} has a minimum length of ${minLength}`),
        messageParams: {minLength},
        validateIf,
        validate({value}) {
            return (isString(value) || isArray(value)) && value.length >= minLength
        },
    })
}
