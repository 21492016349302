import { newUUID } from '@peachy/utility-kit-pure'
import { SubscriptionQuoteRequest } from '@punnet/subscription-pure'
import type { QuoteAndBuyStateData } from './quoteAndBuyStore'


function newSubscriptionQuote(state: QuoteAndBuyStateData): SubscriptionQuoteRequest {
    const newQuote =  {
        account: {
            id: newUUID(),
            name: state.company?.name,
            type: 'COMPANY',
            company: state.company,
            contactEmail: state.customerEmailAddress ?? ''
        },
        subscription: {
            id: newUUID(),
            name: state.company?.name,
            timezone: 'Europe/London',
            startDate: state.startDate,
            contactEmail: state.customerEmailAddress ?? ''
        },
        plans: state.plans,
        policies: state.policies.map(p => ({
            ...p,
            startDate: state.startDate,
        }))
    } as SubscriptionQuoteRequest


    return newQuote
}

const updateQuoteRequest = (draft: SubscriptionQuoteRequest, state: QuoteAndBuyStateData): SubscriptionQuoteRequest => {
    return {
        ...draft,
        plans: state.plans,
        policies: state.policies.map(p => ({
            ...p,
            startDate: state.startDate,
        })),
        subscription: {
            ...draft.subscription,
            startDate: state.startDate
        }
    }
}

export function mapToSubscriptionRequest(state: QuoteAndBuyStateData, savedQuote?: SubscriptionQuoteRequest): SubscriptionQuoteRequest {

    const draft = savedQuote ? updateQuoteRequest(savedQuote, state) : newSubscriptionQuote(state)
    draft.quoteRequestId = state.quoteRequestId

    return draft
}
