import { useSubscriptionService } from '@punnet/subscription-web-client'
import { debounce } from '@solid-primitives/scheduled'
import { onCleanup, onMount, type ParentComponent } from 'solid-js'
import { persistQuote } from '../../../services/persistQuote'
import { mapToSubscriptionRequest } from '../../../store/quotes/mapToSubscriptionRequest'
import { subscribeToStore, useStoreActions } from '../../../store/quotes/quoteAndBuyStore'
import { Quote } from '../Quote'
import styles from '../Section.module.css'
import { useSavedSubscriptionQuote } from './PurchaseQuoteProvider'

//TODO: put into app wide config
const PERSIST_QUOTE_DEBOUNCE_TIME = 1000

export const PurchaseQuote: ParentComponent = (props) => {
    const subscriptionService = useSubscriptionService()
    const debouncedPersistQuote = debounce(persistQuote, PERSIST_QUOTE_DEBOUNCE_TIME)
    const savedQuote = useSavedSubscriptionQuote()
    let unsubscribe: () => void

    onMount(() => {
        useStoreActions.requestQuote()

        unsubscribe = subscribeToStore((state) => {
            const request = mapToSubscriptionRequest(state, savedQuote())
            debouncedPersistQuote(subscriptionService, request)
        })
    })
    
    onCleanup(() => unsubscribe?.())
    
    return (
        <div class={styles.StripeySections}>
            <Quote savedQuote={savedQuote()}>
                {props.children}
            </Quote>
        </div>
    )
}