import {Properties} from '@peachy/utility-kit-pure'
import { AnEvent } from '@punnet/core-event-pure'
import type { LifeEventData } from '../../notifications/notification-types'
import type { AccountType } from '../../../domain/models/Account'

export class MemberPolicyDocSendRequest extends AnEvent implements LifeEventData {
    constructor(from: Properties<MemberPolicyDocSendRequest>) {
        super()
        Object.assign(this, from)
    }
    cognitoId: string

    accountId: string
    accountType: AccountType
    lifeId: string
    policyId: string
    subscriptionId: string
}
