import { TextBox } from '@punnet/web-client-kit-library'
import { type Component } from 'solid-js'
import { useManageStoreActions } from '../../../../app/store/quotes/manageEmployeesStore'
import type { LifeRowCellProps } from '../ManageEmployeesTable'

export const EmailCell: Component<LifeRowCellProps> = (props) => {
    const { updateLife } = useManageStoreActions()

    return (
        <td>
            <TextBox value={props.life?.email}
                onInput={(email) => updateLife(props.policy.id, { ...props.life, email })}
                placeholder={'Email'}
            />
        </td>
    )
}
