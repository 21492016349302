import type { SubscriptionQuoteResponse } from '@punnet/subscription-pure'
import type { QuoteAndBuyStateData } from './quoteAndBuyStore'

export function mapFromSubscriptionRequest(quote: SubscriptionQuoteResponse): QuoteAndBuyStateData {
    const plans = quote.plans
    const policies = quote.policies
    const company = quote.account.company
    const startDate = quote.subscription.startDate
    const customerEmailAddress = quote.account.contactEmail
    const quoteRequestId = quote.quoteRequestId

    return {
        plans,
        policies,
        company,
        startDate,
        customerEmailAddress,
        quoteRequestId
    }
}