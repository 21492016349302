import { Life } from '../../../models/Life'
import { LifeBenefit } from '../../../models/LifeBenefit'
import { isCancelled, type HasLifecycleStatus } from '../../../models/LifecycleStatus'
import { Policy } from '../../../models/Policy'
import { Subscription } from '../../../models/Subscription'

import { mapById, values } from '@peachy/utility-kit-pure'

import { latestOf } from '../../alteration-kit/loose-end-kit'

export function reactivateSubscription(
    subscription: Subscription,
    effectiveDate: number,
): Subscription {

    const reactivatedSubscription: Subscription = {...subscription}
    ensureActiveStatus(reactivatedSubscription)

    const reactivatedPolicies = values(reactivatedSubscription.policies)
        .filter(policy => isCancelled(policy) && policy.endDate >= subscription.endDate)
        .map(policy => reactivatePolicy(policy, effectiveDate))

    delete reactivatedSubscription.totalMonthlyPremium

    reactivatedSubscription.policies = {
        ...reactivatedSubscription.policies,
        ...mapById(reactivatedPolicies),
    }

    return reactivatedSubscription
}


export function reactivatePolicy(
    policy: Policy,
    effectiveDate: number,
): Policy {

    const reactivatedPolicy: Policy = {...policy}
    ensureActiveStatus(reactivatedPolicy)

    const reactivatedLives = values(reactivatedPolicy.lives)
        .filter(life => isCancelled(life) && life.endDate >= policy.endDate)
        .map(life => reactivateLife(life, effectiveDate)
        )
    delete reactivatedPolicy.totalMonthlyPremium

    reactivatedPolicy.lives = {
        ...reactivatedPolicy.lives,
        ...mapById(reactivatedLives),
    }

    return reactivatedPolicy
}

export function reactivateLife(
    life: Life,
    effectiveDate: number,
) {

    const reactivatedLife: Life = {...life}

    const reactivatedBenefits = values(reactivatedLife.benefits)
        .filter(benefit => isCancelled(benefit) && benefit.endDate >= life.endDate)
        .map(benefit => reactivateLifeBenefit(benefit, effectiveDate)
        )
    delete reactivatedLife.totalMonthlyPremium

    reactivatedLife.benefits = {
        ...life.benefits,
        ...mapById(reactivatedBenefits),
    }
    return reactivatedLife
}


export function reactivateLifeBenefit(
    benefit: LifeBenefit,
    effectiveDate: number,
): LifeBenefit {

    const reactivatedBenefit: LifeBenefit = {...benefit}

    ensureActiveStatus(reactivatedBenefit)
    reactivatedBenefit.moriDate = latestOf(effectiveDate, reactivatedBenefit.moriDate)
    delete reactivatedBenefit.premium

    return reactivatedBenefit
}


export function ensureActiveStatus<T extends HasLifecycleStatus>(item: T) {
    item.status = 'ACTIVE'
    delete item.endDate
    delete item.cancellationReason
}
