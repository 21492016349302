import type { Accessor, ParentProps } from 'solid-js'
import type { SubscriptionQuoteRequest, SubscriptionQuoteResponse } from '@punnet/subscription-pure'
import { useSubscriptionService } from '@punnet/subscription-web-client'
import { useParams } from '@solidjs/router'
import { Show, createContext, createEffect, createResource, createSignal, useContext } from 'solid-js'
import { mapFromSubscriptionRequest } from '../../../store/quotes/mapFromSubscriptionRequest'
import { useStoreActions } from '../../../store/quotes/quoteAndBuyStore'
import { Pulse } from '../../../../components/Pulse/Pulse'

type ContextState = {
    savedSubscriptionQuote: Accessor<SubscriptionQuoteRequest | null>
}

const Context = createContext<ContextState>()

async function loadQuote() {
    const response = await useSubscriptionService().loadSmeDraftQuote({ quoteId: useParams().quoteId })
    return response
}

export const PurchaseQuoteProvider = (props: ParentProps) => {
    const [savedSubscriptionQuote] = createResource(loadQuote)
    const [isReady, setIsReady] = createSignal(false)

    createEffect(() => {
        if (savedSubscriptionQuote.state === 'ready') {
            useStoreActions.hydrate(mapFromSubscriptionRequest(savedSubscriptionQuote() as SubscriptionQuoteResponse))
            setIsReady(true)
        }
    })

    return (
        <>
            <Show when={ !savedSubscriptionQuote.error && isReady() }>
                <Context.Provider value={{ savedSubscriptionQuote }}>
                    {props.children}
                </Context.Provider>
            </Show>
            <Show when={savedSubscriptionQuote.error}>
                <div>No quote found for id: {useParams().quoteId}</div>
            </Show>
            <Pulse isShown={savedSubscriptionQuote.loading} />
        </>
    )
}

export function useSavedSubscriptionQuote() {
    return useContext(Context).savedSubscriptionQuote
}

