import {Alteration, mapNestedAlterationsById} from '@punnet/alterations-pure'

import {entries, isNotNullish, values} from '@peachy/utility-kit-pure'
import {MultiMap} from 'mnemonist'

import {AccountType, Subscription, Policy, Life, type CancellationType, type HasLifecycleStatus, isCancelled, type LifeTurnover, type LifeEventData} from '@punnet/subscription-pure'

import {toClass} from '@punnet/model-validation-pure'

import type { AnEvent } from '@peachy/core-domain-pure'

import {
    SubscriptionActivatedNotification,
    SubscriptionAlteredNotification,
    SubscriptionCancelledNotification,
    LifeActivatedNotification,
    LifeAlteredNotification,
    LifeCancelledNotification
} from '@punnet/subscription-pure'
import type { AlterationType } from '../../domain/alteration/alteration-kit/subscription-alteration-types'


export function gatherSubscriptionNotifications(
    accountId: string,
    accountType: AccountType,
    subscription: Subscription,
    alterations: Alteration<AlterationType>[],
) {

    subscription = toClass(subscription, Subscription)
    const subscriptionId = subscription.id

    const alterationMap = mapNestedAlterationsById(alterations)

    const notifications: AnEvent[] = []

    for (const policy of values(subscription.policies)) {
        notifications.push(...gatherPolicyNotifications(
            accountId, accountType, subscriptionId, toClass(policy, Policy), alterationMap
        ))
    }

    notifications.push(...subscriptionNotificationsFor(
        accountType, accountId, subscription, alterations))
    return notifications
}

function subscriptionNotificationsFor(
    accountType: AccountType,
    accountId: string,
    subscription: Subscription,
    alterations: Alteration<AlterationType>[]
) {
    const subAlts = alterations.filter(a =>
        a.id === subscription.id || a.path.includes(subscription.id)
    )

    return subAlts
        .map(subAlt => subscriptionNotificationFor(accountType, accountId, subscription, subAlt, subAlts))
        .filter(isNotNullish)
}


function subscriptionNotificationFor(
    accountType: AccountType,
    accountId: string,
    subscription: Subscription,
    alteration: Alteration<AlterationType>,
    subscriptionAlterations: Alteration<AlterationType>[],
) {

    switch (alteration.type) {
        case 'SUBSCRIPTION-ADDED':
        case 'SUBSCRIPTION-REACTIVATED': {
            return new SubscriptionActivatedNotification({
                accountId, accountType, subscriptionId: subscription.id,
            })
        }
        case 'SUBSCRIPTION-ALTERED':
        case 'SUBSCRIPTION-TRANSFERRED-IN':
        case 'SUBSCRIPTION-RENEWED': {
            return new SubscriptionAlteredNotification({
                accountId, accountType, subscriptionId: subscription.id,
                lifeTurnover: gatherLifeTurnover(subscription.id, subscriptionAlterations)
            })

        }
        case 'SUBSCRIPTION-CANCELLED':
        case 'SUBSCRIPTION-TRANSFERRED-OUT': {
            return new SubscriptionCancelledNotification({
                accountId, accountType, subscriptionId: subscription.id,
                cancellationReason: subscription.cancellationReason,
                cancellationType: cancellationTypeFor(subscription)
            })
        }
        default:
            return null
    }


}

function gatherPolicyNotifications(
    accountId: string,
    accountType: AccountType,
    subscriptionId: string,
    policy: Policy,
    alterationMap: MultiMap<string, Alteration<AlterationType>>,
) {
    const notifications: AnEvent[] = []
    const notifiedLives = new Set<string>()

    for (const [lifeId, life] of entries(policy.lives)) {

        const lifeAlterations = alterationMap.get(lifeId) ?? []
        if (lifeAlterations.length) {
            const lifeNotifications = lifeNotificationsFor(accountType, lifeAlterations, life)
            notifications.push(...lifeNotifications)
            notifiedLives.add(life.id)
        }
    }

    const additionalLivesToNotify = policy.getActiveLives().filter(l => !notifiedLives.has(l.id))
    const policyAlterations = alterationMap.get(policy.id) ?? []

    if (policyAlterations.find(a => a.type === 'POLICY-ALTERED' || a.type === 'POLICY-TRANSFERRED-IN')) {
        additionalLivesToNotify.map(life => {
            notifications.push(new LifeAlteredNotification({
                accountId,
                accountType,
                subscriptionId,
                policyId: policy.id,
                lifeId: life.id,
                cognitoId: life.cognitoUserId,
                lifeTurnover: gatherLifeTurnover(subscriptionId, policyAlterations)
            }))
        })
    }
    return notifications
}


function lifeNotificationsFor(accountType: AccountType, lifeAlterations: Alteration<AlterationType>[], life: Life) {
    return lifeAlterations
        .map(lifeAlteration => lifeNotificationFor(accountType, lifeAlteration, life))
        .filter(isNotNullish)
}

function lifeNotificationFor(accountType: AccountType, alteration: Alteration<AlterationType>, life: Life): AnEvent {
    switch (alteration.type) {
        case 'LIFE-ADDED':
        case 'LIFE-REACTIVATED': {
            return new LifeActivatedNotification(lifeEventDataFor(accountType, alteration, life))
        }
        case 'LIFE-ALTERED':
        case 'LIFE-TRANSFERRED-IN':
        case 'LIFE-RENEWED': {
            return new LifeAlteredNotification(lifeEventDataFor(accountType, alteration, life))
        }
        case 'LIFE-CANCELLED': {
            return new LifeCancelledNotification({
                ...lifeEventDataFor(accountType, alteration, life),
                cancellationReason: life.cancellationReason,
                cancellationType: cancellationTypeFor(life)
            })
        }
        case 'LIFE-TRANSFERRED-OUT':
        default:
            return null
    }
}


function lifeEventDataFor(
    accountType: AccountType,
    lifeLevelAlteration: Alteration<AlterationType>,
    life: Life,
): LifeEventData {
    const [accountId, subscriptionId, policyId] = lifeLevelAlteration.path
    return {
        accountId,
        accountType,
        subscriptionId,
        policyId,
        lifeId: life.id,
        cognitoId: life.cognitoUserId,
    }
}


function cancellationTypeFor(item: HasLifecycleStatus): CancellationType {

    if (isCancelled(item)) {
        const startDate = item.startDate
        const endDate = item.endDate

        const lifetimeMillis = endDate - startDate
        const lifetimeDays = lifetimeMillis / (1000 * 60 * 60 * 24)

        const hasClaims = false

        if (lifetimeDays < 14) {
            return hasClaims ? 'INSIDE_PERIOD_CLAIMS' : 'INSIDE_PERIOD_NO_CLAIMS'
        } else {
            return 'OUTSIDE_PERIOD'
        }
    }
    return null
}


function gatherLifeTurnover(subscriptionId: string, alterations: Alteration<AlterationType>[]): LifeTurnover {
    return {
        addedLifeIds: alterations.filter(
            a => a.type === 'LIFE-ADDED' || a.type === 'LIFE-REACTIVATED' || a.type === 'LIFE-TRANSFERRED-IN'
        ).map(a => a.id),
        removedLifeIds: alterations.filter(
            a => a.type === 'LIFE-CANCELLED' || a.type === 'LIFE-TRANSFERRED-OUT'
        ).map(a => a.id)
    }
}
