import { Properties } from '@peachy/utility-kit-pure'
import { AnEvent } from '@punnet/core-event-pure'

import { LifeEventData, LifeTurnover } from './notification-types'
import { AccountType } from '../../domain/models/Account'

export class LifeAlteredNotification extends AnEvent implements LifeEventData {
    constructor(from: Properties<LifeAlteredNotification>) {
        super()
        Object.assign(this, from)
    }

    lifeTurnover?: LifeTurnover

    accountId: string
    accountType: AccountType
    subscriptionId: string
    policyId: string
    cognitoId: string
    lifeId: string
}
