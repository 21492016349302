import { AnEvent } from '@punnet/core-event-pure'

import {Props} from '@peachy/utility-kit-pure'

export class IntercomEventRequest extends AnEvent {
    intercomId: string

    eventType: string

    payload: any

    constructor(from: Props<IntercomEventRequest>) {
        super()
        Object.assign(this, from)
    }
}


