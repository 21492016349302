import {defineValidator, MessageProducer} from '../validation-core'
import cardValidator from 'card-validator'
import {isNullish} from '@peachy/utility-kit-pure'

export default function IsPaymentCardExpiry<T>(
    message?: string | MessageProducer<T>
) {
    return defineValidator<T>({
        name: 'IsPaymentCardExpiry',
        message: 'Please enter a valid expiry date',
        validate({value}) {
            return !isNullish(value) && cardValidator.expirationDate(value).isValid
        },
    })
}
