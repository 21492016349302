import { type Properties } from '@peachy/utility-kit-pure'
import { AnEvent } from '@punnet/core-event-pure'
export class IndividualSanctionsCheckRequest extends AnEvent {
    constructor(from: Properties<IndividualSanctionsCheckRequest>) {
        super()
        Object.assign(this, from)
    }
    firstname: string
    lastname: string
    birthdate: string
    cognitoId: string
    email?: string
}
