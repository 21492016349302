import type { Component } from 'solid-js'
import { Toggle } from '@punnet/web-client-kit-library'
import type { Benefit } from '@punnet/product-client'
import { values } from '@peachy/utility-kit-pure'
import { usePlans, useStoreActions } from '../../../app/store/quotes/quoteAndBuyStore'
import styles from './Eap.module.css'

//TODO: probably not ideal this is dependent on the store, but ok for now

const isSelected = (benefit: Benefit) => {
    const plans = usePlans
    return values(plans()[0].benefits).some(b => b.id === benefit.benefitTypes[0])
}

export const Eap: Component<{ benefit: Benefit }> = (props) => {
    const { addBenefit, removeBenefit } = useStoreActions

    const handleToggle = (isOn: boolean) => {
        props.benefit.isActive = isOn

        if (isOn) {
            addBenefit(props.benefit)
        } else {
            removeBenefit(props.benefit)
        }
    }

    return <div class={styles.eap}>
        <div><Toggle isOn={isSelected(props.benefit)} onToggle={handleToggle} /></div>
        <div class={styles.description}>£0.50 per member</div>
    </div>
}