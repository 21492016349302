import { type ModalProps, Modal } from '@punnet/web-client-kit-library'
import { createSignal } from 'solid-js'

import { IamPanel } from '@punnet/iam-client'

import styles from './Iam.module.css'

export function Iam() {
    const [email, setEmail] = createSignal('')  
    return <IamPanel class={styles.IamPanel} email={email} setEmail={setEmail}/>
    // return <IamPanel class={styles.IamPanel} email={email} setEmail={setEmail} spinner={Spinner} />
}   

