import { onMount, type ParentComponent } from 'solid-js'
import { Quote } from '../Quote'
import styles from './NewQuote.module.css'
import { useStoreActions } from '../../../store/quotes/quoteAndBuyStore'

export const NewQuote: ParentComponent = (props) => {

    onMount(() => {
        useStoreActions.clear()
    })

    return (
        <div class={styles.NewQuote}>
            <Quote>
                {props.children}
            </Quote>
        </div>
    )
}