import { createSignal } from 'solid-js'
import { Label, Title } from '../Quote'
import { NavigationButtons } from './NavigationButtons'
import { FormSection, TextSection } from '../Section'
import { CompanySchemeHeader } from './CompanySchemeHeader'
import styles from '../Quote.module.css'

// state is here for now so data is persisted between page transitions
const [upgrade, setUpgrade] = createSignal<boolean | undefined>(undefined)
const [addAdult, setAddAdult] = createSignal<boolean | undefined>(undefined)

export const Upgrades = () => {
    const isValid = () => upgrade() !== undefined && addAdult() !== undefined

    return (
        <>
            <TextSection>
                <CompanySchemeHeader />
                <p>Set your employee upgrades, and notification preferences for allowed upgrades.</p>
                <p>You can also do this later in the employer admin portal.</p>
            </TextSection>

            <FormSection>
                <Title>Employee upgrades:</Title>

                <div class={styles.Section}>
                    <Label>Upgrade plan levels</Label>
                    <p>Allow employees to select a higher level of cover, if available</p>
                    <p>
                        Charges will apply to your payment method and appear on your invoice.
                        These charges can be recovered from the employee via payroll deduction
                    </p>

                    <label><input required type="radio" name="upgrade" value={'yes'} checked={upgrade() === true} onChange={() => setUpgrade(true)}/>Yes</label>
                    <label><input required type="radio" name="upgrade" value={'no'} checked={upgrade() === false} onChange={() => setUpgrade(false)}/>No</label>
                </div>

                <div class={styles.Section}>
                    <Label>Add an adult to their plan</Label>
                    <p>Allow employees to add one adult to their plan at their chosen cover level</p>
                    <p>
                        Charges will apply to your payment method and appear on your invoice. 
                        These charges can be recovered from the employee via payroll deduction
                    </p>
                    <label><input required type="radio" name="addAdult" value={'yes'} checked={addAdult() === true} onChange={() => setAddAdult(true)}/>Yes</label>
                    <label><input required type="radio" name="addAdult" value={'no'} checked={addAdult() === false} onChange={() => setAddAdult(false)}/>No</label>
                </div>
            </FormSection>

            <TextSection>
                <NavigationButtons nextButtonDisabled={!isValid()} />
            </TextSection>
        </>
    )
}