import { type Benefit } from '@punnet/product-client'
import type { DisplayBenefitConfig, DisplayPlanConfig } from '@punnet/web-client-kit-pure'
import { BENEFITS } from '@sovereign/assets'

import { BenefitLabel } from './BenefitLabel'
import { Currency } from './controls/Currency'
import { ShowSelected } from './controls/ShowSelected'
import { Eap } from './controls/Eap'

const benefits = {
    OPTICAL: {
        display: (benefit: Benefit) => <Currency value={benefit.defaultLimit as number} />,
        description: () => <><b>100%</b> payback for each adult</>,
        moreInfo: () => <>We will refund the amount you have paid to a qualified optical practitioner up to the appropriate maximum amount in each claiming year. This maximum amount depends on your level of cover.</>,
        icon: () => <BENEFITS.OPTICAL/>
    },
    DENTAL: {
        display: (benefit: Benefit) => <Currency value={benefit.defaultLimit as number} />,
        description: () => <><b>100%</b> payback for each adult</>,
        moreInfo: () => <>We will refund the amount you have paid to a qualified NHS or private dental practitioner up to the appropriate maximum amount in each claiming year. This maximum amount depends on your level of cover.</>,
        icon: () => <BENEFITS.DENTAL/>
    },
    PRESCRIPTION: {
        display: (benefit: Benefit) => <Currency value={benefit.defaultLimit as number} />,
        description: () => <><b>100%</b> payback for adults, <b>50%</b> payback for children</>,
        moreInfo: () => <>More info on prescriptions</>,
        icon: () => <BENEFITS.PRESCRIPTION/>
    },
    PHYSIO: {
        display: (benefit: Benefit) => <Currency value={benefit.defaultLimit as number} />,
        description: () => <><b>100%</b> payback for adults, <b>50%</b> payback for children</>,
        moreInfo: () => <>More info on physio</>,
        icon: () => <BENEFITS.PHYSIO/>
    },
    CHIROPODY: {
        display: (benefit: Benefit) => <Currency value={benefit.defaultLimit as number} />,
        description: () => <><b>100%</b> payback for each adult</>,
        moreInfo: () => <>More info on chiropody</>,
        icon: () => <BENEFITS.CHIROPODY/>
    },
    ACUPUNCTURE: {
        display: (benefit: Benefit) => <Currency value={benefit.defaultLimit as number} />,
        description: () => <><b>100%</b> payback for each adult</>,
        moreInfo: () => <>More info on accupuncture</>,
        icon: () => <BENEFITS.ACUPUNCTURE/>
    },
    HEALTH_SCREENING: {
        display: (benefit: Benefit) => <Currency value={benefit.defaultLimit as number} />,
        description: () => <><b>100%</b> payback for each adult</>,
        moreInfo: () => <>More info on health screening</>,
        icon: () => <BENEFITS.HEALTH_SCREENING/>
    },
    CONSULTATIONS_DIAGNOSTICS: {
        display: (benefit: Benefit) => <Currency value={benefit.defaultLimit as number} />,
        description: () => <><b>100%</b> payback for adults, <b>50%</b> payback for children</>,
        moreInfo: () => <>More info on hospital consultations & diagnostics</>,
        icon: () => <BENEFITS.CONSULTATIONS_DIAGNOSTICS/>
    },
    HOSPITAL_IN_PATIENT: {
        display: (benefit: Benefit) => <Currency value={benefit.defaultLimit as number} />,
        description: () => <><b>5 days/nights</b> for each adult</>,
        moreInfo: () => <>More info on hospital in-patient</>,
        icon: () => <BENEFITS.HOSPITAL_IN_PATIENT/>
    },
    BIRTH_ADOPTION: {
        display: (benefit: Benefit) => <Currency value={benefit.defaultLimit as number} />,
        description: () => <><b>5 days/nights</b> for each adult</>,
        moreInfo: () => <>More info on hospital consultations & diagnostics</>,
        icon: () => <BENEFITS.BIRTH_ADOPTION/>
    },
    GP_SERVICE: {
        display: (benefit: Benefit) => <ShowSelected benefit={benefit} />,
        description: () => <><b>Unlimited</b> appointments for each member</>,
        moreInfo: () => <>More info on unlimited GP</>,
        icon: () => <BENEFITS.GP_SERVICE/>
    },
    SOVEREIGN_PERKS: {
        display: (benefit: Benefit) => <ShowSelected benefit={benefit} />,
        description: () => <>Online and high street discounts</>,
        moreInfo: () => <>More info on Sovereign Perks</>,
        icon: () => <BENEFITS.SOVEREIGN_PERKS/>
    },
    HEALTH_WELLBEING: {
        display: (benefit: Benefit) => <ShowSelected benefit={benefit} />,
        description: () => <>Access to the Sovereign Resource Hub</>,
        moreInfo: () => <>More info on health and wellbeing</>,
        icon: () => <BENEFITS.HEALTH_WELLBEING/>
    },
    EAP: {
        display: (benefit: Benefit) => <Eap benefit={benefit} />,
        description: () => <></>,
        moreInfo: () => <>More info on EAP</>,
        icon: () => <BENEFITS.EAP/>
    }
} as DisplayBenefitConfig

export const displayConfig: DisplayPlanConfig = {
    benefits,
    benefitLabel: (benefit: Benefit) => <BenefitLabel benefit={benefit} icon={benefits[benefit.id].icon()} description={benefits[benefit.id].description()}/>,
    benefitGroups: [
        {
            label: 'Meet DSE Obligations',
            benefits: ['OPTICAL']
        },
        {
            label: 'Support health & wellbeing',
            benefits: ['DENTAL', 'PRESCRIPTION', 'PHYSIO', 'CHIROPODY', 'ACUPUNCTURE']
        },
        {
            label: 'Get to a faster diagnosis',
            benefits: ['HEALTH_SCREENING', 'CONSULTATIONS_DIAGNOSTICS', 'HOSPITAL_IN_PATIENT']
        },
        {
            label: 'Support if you need a helping hand',
            benefits: ['BIRTH_ADOPTION']
        },
        {
            label: 'Member benefits',
            benefits: ['GP_SERVICE', 'SOVEREIGN_PERKS', 'HEALTH_WELLBEING']
        },
        {
            label: 'Meet your duty of care obligations',
            benefits: ['EAP']
        }
    ]
}
