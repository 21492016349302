import { DateBox } from '@punnet/web-client-kit-library'
import { type Component } from 'solid-js'
import { useManageStoreActions } from '../../../../app/store/quotes/manageEmployeesStore'
import type { LifeRowCellProps } from '../ManageEmployeesTable'

export const BirthDateCell: Component<LifeRowCellProps> = (props) => {
    const { updateLife } = useManageStoreActions()

    // TODO-MN Cleanup later
    return <td>
        <DateBox placeholder={'dd/mm/yyyy'}
            selectedDate={props.life.dateOfBirth}
            revertOnBlurIfInvalid
            onDateEntered={dateOfBirth => updateLife(props.policy.id, { ...props.life, dateOfBirth })}
        />
    </td>
}
