import type { SubscriptionQuoteRequest } from '@punnet/subscription-pure'
import type { ParentComponent } from 'solid-js'
import { QuoteController } from './QuoteController/QuoteController'
import styles from './Quote.module.css'

export const Title: ParentComponent<{}> = (props) => {
    return <h2>{props.children}</h2>
}

export const Label: ParentComponent<{}> = (props) => {
    return <h3>{props.children}</h3>
}

export const Quote: ParentComponent<{ savedQuote?: SubscriptionQuoteRequest | undefined }> = (props) => {
    return (
        <QuoteController savedQuote={props.savedQuote}>
            <div class={styles.Quote}>
                {props.children}
            </div>
        </QuoteController>
    )
}