import { PlanConfiguration, createToggleSignal, untrack } from '@punnet/web-client-kit-library'
import { Show } from 'solid-js'
import { usePlanConfig } from '../../app/hooks/usePlanConfig'
import { getPermissions } from '../../app/services/permissions/PermissionsController'
import { validateLifeField } from '../../app/services/validation/validateLifeField'
import { createLife } from '../../app/store/quotes/createLife'
import { MIN_POLICIES, usePlans, usePolicies, useStartDate, useStoreActions } from '../../app/store/quotes/quoteAndBuyStore'
import { PrimaryButton } from '../Button/Buttons'
import { ManageEmployeesModal } from '../ManageEmployees/modal/ManageEmployeesModal'
import styles from './PlanBuilder.module.css'
import { PlanMembership } from './PlanMembership'
import { displayConfig } from './config'

const MAX = 100, MIN = 0

export function PlanBuilder() {
    const planConfig = usePlanConfig()
    const permissions = getPermissions()
    
    const { addLife, removePolicy } = useStoreActions
    const plans = usePlans, policies = usePolicies

    const getCountForPlan = (planId: string) => policies().filter(policy => Object.values(policy.lives).some(life => life.planId === planId)).length
    const isPlanActive = (planId: string) => getCountForPlan(planId) > 0

    const activeClass = styles.planCardActive

    const add = (planId: string) => addLife(createLife({ planId: planId, type: 'PRIMARY' }))

    const remove = (planId: string) => {
        const policy = policies().find(policy => Object.values(policy.lives).some(life => life.planId === planId))
        if (policy) {
            removePolicy(policy)
        }
    }

    return (
        <div class={styles.planBuilder}>
            <PlanConfiguration
                planConfig={planConfig}
                displayConfig={displayConfig}
                plans={plans()}
                isPlanActive={isPlanActive}
                class={styles.planConfiguration}
                planActiveClass={activeClass}
            />
            <div class={styles.employeeCardContainer}>
                <div class={styles.planActionsContainer}>
                    <ManageEmployees />
                </div>
                <div class={styles.planInfoContainer}>
                    <PlanMembership
                        plans={plans()}
                        policies={policies()}
                        min={MIN}
                        max={MAX}
                        count={getCountForPlan}
                        onAdd={add}
                        onRemove={remove}
                        isPlanActive={isPlanActive}
                        isEditable={permissions.canEditEmployeeCounts()}
                        class={styles.planMembership}
                        planActiveClass={activeClass}
                    />
                </div>
            </div>
        </div>
    )
}


const ManageEmployees = () => {
    const [isOpen, toggleIsOpen] = createToggleSignal(false)

    // untrack - we dont want to pass in the signals, just the values
    const policies = () => untrack(usePolicies())
    const plans = () => untrack(usePlans())
    const startDate = () => untrack(useStartDate())

    const permissions = getPermissions()

    return (
        <>
            <Show when={permissions.canEditEmployeeDetails()}>
                <PrimaryButton onClick={toggleIsOpen}>Add employee details</PrimaryButton>

                <ManageEmployeesModal
                    isOpen={isOpen()}
                    onDismiss={toggleIsOpen}
                    plans={plans()}
                    policies={policies()}
                    startDate={startDate()}
                    minPolicyCount={MIN_POLICIES}
                    onUpdate={useStoreActions.updatePolicies}
                    lifeValidation={
                        {
                            firstName: (life) => validateLifeField(life, 'firstname'),
                            lastName: (life) => validateLifeField(life, 'lastname')
                        }
                    }
                />
            </Show>
        </>
    )
}