import { Toggle } from '@punnet/web-client-kit-library'
import { DirectDebitLogo } from '@sovereign/assets'
import { createSignal } from 'solid-js'
import { Label, Title } from '../Quote'
import quoteStyles from '../Quote.module.css'
import { FormSection, TextSection } from '../Section'
import { CompanySchemeHeader } from './CompanySchemeHeader'
import { NavigationButtons } from './NavigationButtons'
import { createStore } from 'solid-js/store'
import styles from './DirectDebitDetails.module.css'

// storing this here in lieu of storing in our actual store (for now)
const [fieldStore, updateFieldStore] = createStore({
    accountName: '',
    sortCode: '',
    accountNumber: '',
    collectionDate: 1,
    agree: false
})

// these will be the 'working state'
const [accountName, setAccountName] = createSignal('')
const [sortCode, setSortCode] = createSignal('')
const [accountNumber, setAccountNumber] = createSignal('')
const [collectionDate, setCollectionDate] = createSignal<number>(1)
const [agree, setAgree] = createSignal(false)

export const DirectDebitDetails = () => {
    const isValid = () => accountName() && sortCode() && accountNumber() && collectionDate() && agree()

    return <>
        <TextSection>
            <CompanySchemeHeader />
            <p>Please add direct debit details and agree to the Direct Debit guarantee, and hit Submit once you are ready</p>
        </TextSection>

        <FormSection>
            <Title>Payment:</Title>
            <div class={styles.DirectDebitLabel}>
                <Label>Direct Debit details</Label>
                <div class={styles.DirectDebitLogo}><DirectDebitLogo /></div>
            </div>

            <div class={quoteStyles.Section}>
                <p>You have chosen to set up a Variable Direct Debit.</p>
                <p>Subject to your rights under The Direct Debit Guarantee, this will automatically debit payments due on your account from your bank or building society account.</p>
                <p>If you are a joint signatory on the account, a paper direct debit instruction can be downloaded for completion.</p>
            </div>

            <div class={quoteStyles.Section}>
                <DirectDebitForm />
            </div>

            <div class={quoteStyles.Section}>
                <label>
                    <input required type="checkbox" name="agree" checked={agree()} onChange={(e) => setAgree(e.target.checked)} />
                    I understand and agree to the Terms and Conditions
                </label>
            </div>

            <div class={quoteStyles.Section}>
                <DirectDebitCollectionDate />
            </div>
        </FormSection>

        <TextSection>
            <NavigationButtons nextButtonDisabled={!isValid()} nextButtonText="Submit" />
        </TextSection>
    </>
}

const DirectDebitForm = () => {
    const formatSortCode = (sortCode: string) => {
        const digits = sortCode.replace(/-/g, '')
        return digits.replace(/(\d{2})(?=\d)/g, '$1-')
    }
    
    const handleSortCodeInput = (e: any) => {
        e.target.value = formatSortCode(e.target.value)
        setSortCode(e.target.checkValidity() ? e.target.value : '')
    }

    const handleAccountNameInput = (e: any) => setAccountName(e.target.checkValidity() ? e.target.value : '')
    const handleAccountNumberInput = (e: any) => setAccountNumber(e.target.checkValidity() ? e.target.value : '')
    const handleAccountNameChange = (e: any) => updateFieldStore('accountName', accountName())
    const handleSortCodeChange = (e: any) => updateFieldStore('sortCode', sortCode())
    const handleAccountNumberChange = (e: any) => updateFieldStore('accountNumber', accountNumber())

    return (
        <>
            <div class={styles.Form}>
                <input name="accountName" class={styles.AccountName} type="text" placeholder="Account name" required value={fieldStore.accountName} onInput={handleAccountNameInput} onChange={handleAccountNameChange} autocomplete="off" />
                <input name="sortCode" class={styles.SortCode} type="text" placeholder="Sort code" required minLength={8} maxLength={8} value={fieldStore.sortCode} onInput={handleSortCodeInput} onChange={handleSortCodeChange} autocomplete="off" />
                <input name="accountNumber" class={styles.AccountNumber} type="text" placeholder="Account number" required minLength={8} maxLength={8} value={fieldStore.accountNumber} onInput={handleAccountNumberInput} onChange={handleAccountNumberChange} autocomplete="off" />
            </div>
            <div class={styles.BillingAddress}>
                <span>Different billing address to company address?</span>
                <Toggle isOn={false} onToggle={() => { }} />
            </div>
        </>
    )
}

const DirectDebitCollectionDate = () => {
    return <>
        <Label>Direct Debit collection date</Label>
        <p>Your Direct Debit collection date will default to your company scheme start date, however you can choose a different billing date below</p>
        <div class={styles.CollectionDate}>
            <select name="collectionDate" required value={collectionDate()} onChange={(e) => setCollectionDate(parseInt(e.target.value))}>
                <option value="1">1st of the month</option>
                <option value="2">2nd of the month</option>
                <option value="3">3rd of the month</option>
                <option value="4">4th of the month</option>
                <option value="5">5th of the month</option>
                <option value="6">6th of the month</option>
                <option value="7">7th of the month</option>
                <option value="8">8th of the month</option>
                <option value="9">9th of the month</option>
                <option value="10">10th of the month</option>
                <option value="11">11th of the month</option>
                <option value="12">12th of the month</option>
                <option value="13">13th of the month</option>
                <option value="14">14th of the month</option>
                <option value="15">15th of the month</option>
                <option value="16">16th of the month</option>
                <option value="17">17th of the month</option>
                <option value="18">18th of the month</option>
                <option value="19">19th of the month</option>
                <option value="20">20th of the month</option>
                <option value="21">21st of the month</option>
                <option value="22">22nd of the month</option>
                <option value="23">23rd of the month</option>
                <option value="24">24th of the month</option>
                <option value="25">25th of the month</option>
                <option value="26">26th of the month</option>
                <option value="27">27th of the month</option>
                <option value="28">28th of the month</option>
                <option value="29">29th of the month</option>
                <option value="30">30th of the month</option>
                <option value="31">31st of the month</option>
            </select>
        </div>
    </>
}